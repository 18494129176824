import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from "@fullcalendar/bootstrap5";
import DatePicker from "react-datepicker";
import { Popover, PopoverHeader, PopoverBody, ModalHeader, Modal, ModalBody, Button, Spinner, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip, ModalFooter } from "reactstrap";
import { useForm } from "react-hook-form";
import { Col, Row, RSelect, Icon } from "../../Component";
import { authLogin, debounce, formatFileSize, setDateForPicker, shortenString } from "../../../utils/Utils";
import "../../../css/calender-custom.css";
import {
  handleDropChange,
  MakeApiCallBlobImage,
} from "../../../utils/helper";
import {
  eventOptions,
  getCategory,
  getfullDate,
  getfullTime,
  subMeetingOptions,
} from "./CalenderData";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Dropzone from "react-dropzone";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { useTheme } from "../../../layout/provider/Theme";
import "./calendar.css";
import Cookies from 'js-cookie';
import { GoDotFill } from "react-icons/go";
import { MdOutlineCategory, MdOutlineEditNote } from "react-icons/md";
import Fancybox from "./Fancybox";
import { postRequestData } from "../../../utils/api-services";
import { form_logo_category, form_logo_date, form_logo_description, form_logo_guests, form_logo_img, form_logo_location, form_logo_mail, form_logo_pen, form_logo_remainder } from "../../../images/applogos";

const EventView = (event) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const { title, extendedProps, publicId } = event.event.event._def;
  return (
    <React.Fragment>
      <div id={publicId} onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)}>
        {title === "null" ? "(No Title)" : title}
      </div>{" "}
      <Popover placement="bottom" isOpen={mouseEnter} target={publicId}>
        <PopoverHeader className="fw-normal">{title === "null" ? "(No Title)" : title}</PopoverHeader>
        {
          extendedProps?.description &&
          <PopoverBody>{shortenString(extendedProps?.description, 100)}</PopoverBody>
        }
      </Popover>
    </React.Fragment>
  );
};

const CalenderApp = ({ events, onDelete, onEdit, UserListRef, onChildData }) => {
  const [modalState, updateModal] = useState(false);
  const [mockEvents, updateEvents] = useState(events);
  const [event, updateEvent] = useState({});
  const [theme, settheme] = useState({
    value: "",
    label: "",
  });
  const [edit, updateEditModal] = useState(false);
  const [dates, setDates] = useState({
    startDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    endDate: new Date(),
  });
  const [loading, setLoading] = useState(false);
  const [files4, setFiles4] = useState([]);
  const [noImg, setNoImg] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const authToken = Cookies.get("authToken");
  const newTheme = useTheme();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdowntoggle = () => setDropdownOpen((prevState) => !prevState);

  const [subDropdownOpen, setSubDropdownOpen] = useState(false);
  const toggleSubDropdown = () => setSubDropdownOpen(!subDropdownOpen);
  const [subMeet, setSubMeet] = useState("");
  const [filterSubMeetingOpt, setFilterSubMeetingOpt] = useState([] || null);
  const [userRole, setUserRole] = useState("");
  const [organizer, setOrganizer] = useState(false);
  const [tooltipText, setTooltipText] = useState('Copy Link');
  const [modalSize, setModalSize] = useState(false);
  const [secondModal, setSecondModal] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [locationModal, setLocationModal] = useState(false);
  const locationToggle = () => setLocationModal(!locationModal);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [meetingLinkTip, setMeetingLinkTip] = useState(false);
  const linkTip = () => setMeetingLinkTip(!meetingLinkTip);

  const [modal, setModal] = useState(false);
  const toggleModal = () => { setModal(!modal); };
  const [isFormTouched, setIsFormTouched] = useState(false);

  useEffect(() => {
    reset(event);
  }, [event]);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    updateEvents(events);
  }, [events]);
  const handleFormSubmit = async (formData) => {
    setLoading(true);
    setTimeout(() => {
      let newEvent = {};
      newEvent = {
        id: event.id,
        eventId: event.eventId,
        className: theme?.value,
        category: event?.category,
        subCategory: event?.category === "Meeting" ? event?.subCategory : "",
        meetingLink: event?.category === "Meeting" ? event?.meetingLink : "",
        parent_id: event.parent_id,
        type: event.type,
        title: formData.title,
        start: event.start,
        startDate: event.startDate,
        startTimeObj: event.startTimeObj,
        end: event.end,
        endDate: event.endDate,
        endTimeObj: event.endTimeObj,
        description: formData.description,
        email: event.email,
        users: event.users,
        location: event.location,
        time: dates,
        event_image: files4?.[0],
        reminder: JSON.stringify(reminderformData),
      };
      onEdit(event.eventId, newEvent);
      settheme("");
      // setTimeout(() => {
      toggleEdit();
      setLoading(false);
      // }, 2000);
    }, 2000); // Wait for 2000 milliseconds (2 seconds) before executing the code
  };
  const toggle = () => {
    updateModal(!modalState);
  };
  const toggleEdit = () => {
    updateEditModal(!edit);
    setIsFormTouched(false);
  };
  const changeThemeByValue = (label) => {
    const te = getCategory(label);
    settheme(te);
  };
  const handleEventClick = async (info) => {
    setFiles4([]);
    setReminderFormData([]);
    const event = events.find((item) => item.id === info.event._def.publicId);

    if (event?.link) {
      window.location.href = event?.link;
      return; // Exit early if there is a link
    }

    setFileLoading(true);
    updateEvent(event);
    // try {
    //   const result = await MakeApiCallBlobImage(`api/v1/calendar-events/get-event-attachment`, getImageBlob);
    //   if (result === null || result === undefined) {
    //     setNoImg(true);
    //   } else {
    //     setFiles4([{ preview: result, name: event.title }]);
    //   }
    // } catch (error) {
    //   setNoImg(true);
    // } finally {
    //   setFileLoading(false);
    // }
    if (Cookies.get("username") === event?.organizer) {
      setOrganizer(true);
    } else {
      setOrganizer(false);
    }
    const remindeArr = event?.reminder?.length > 0 ? JSON.parse(event?.reminder) : [];
    setReminderFormData(remindeArr);
    changeThemeByValue(event.className);
    const te = getCategory(event.className);
    updateEvent({ ...event, type: te });
    toggle();

    if (event?.category || event?.subCategory || event?.meetingLink || event?.description || event?.location) {
      setModalSize(true);
    } else setModalSize(false);
    if (event?.users?.length > 0 || JSON.parse(event?.reminder).length > 0) {
      setSecondModal(true);
    } else setSecondModal(false);

    // const getImageBlob = new FormData();
    // getImageBlob.append("calendar_id", event.eventId);
    // const res = await postRequestData(Cookies.get("username"), "api/v1/public/get-role");
    // if (res?.status) {
    //   const userR = res?.data?.role?.key;
    //   setUserRole(userR);
    // }
    const debouncedApiCall = debounce(async (getImageBlob) => {
      try {
        const result = await MakeApiCallBlobImage(`api/v1/calendar-events/get-event-attachment`, getImageBlob);
        if (result === null || result === undefined) {
          setNoImg(true);
        } else {
          setFiles4([{ preview: result, name: event.title }]);
        }
      } catch (error) {
        setNoImg(true);
      } finally {
        setFileLoading(false);
      }
    }, 300);
    const getImageBlob = new FormData();
    getImageBlob.append("calendar_id", event.eventId);
    debouncedApiCall(getImageBlob); // Call the debounced function

    try {
      const res = await postRequestData(Cookies.get("username"), "api/v1/public/get-role");
      if (res?.status) {
        const userR = res?.data?.role?.key;
        setUserRole(userR);
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };
  // const handleEventClick = async (info) => {
  //   setFiles4([]);
  //   setReminderFormData([]);
  //   const event = events.find((item) => item.id === info.event._def.publicId);
  //   if (event?.link) {
  //     window.location.href = event?.link;
  //     return; // Exit early if there is a link
  //   }
  //   setFileLoading(true);
  //   updateEvent(event);

  //   if (Cookies.get("username") === event?.organizer) {
  //     setOrganizer(true);
  //   } else {
  //     setOrganizer(false);
  //   }

  //   const remindeArr = event?.reminder?.length > 0 ? JSON.parse(event?.reminder) : [];
  //   setReminderFormData(remindeArr);
  //   changeThemeByValue(event?.className);
  //   const te = getCategory(event?.className);
  //   updateEvent({ ...event, type: te });
  //   toggle();

  //   if (event?.category || event?.subCategory || event?.meetingLink || event?.description || event?.location) {
  //     setModalSize(true);
  //   } else setModalSize(false);
  //   if (event?.users?.length > 0 || JSON.parse(event?.reminder).length > 0) {
  //     setSecondModal(true);
  //   } else setSecondModal(false);

  //   const debouncedApiCall = debounce(async (getImageBlob) => {
  //     try {
  //       const result = await MakeApiCallBlobImage(`api/v1/calendar-events/get-event-attachment`, getImageBlob);
  //       if (!result) {
  //         setNoImg(true);
  //       } else {
  //         setFiles4([{ preview: result, name: event.title }]);
  //       }
  //     } catch (error) {
  //       setNoImg(true);
  //     } finally {
  //       setFileLoading(false);
  //     }
  //   }, 500);
  //   const getImageBlob = new FormData();
  //   getImageBlob.append("calendar_id", event.eventId);
  //   debouncedApiCall(getImageBlob); // Call the debounced function

  //   try {
  //     const res = await postRequestData(Cookies.get("username"), "api/v1/public/get-role");
  //     if (res?.status) {
  //       const userR = res?.data?.role?.key;
  //       setUserRole(userR);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching user role:", error);
  //   }
  // };
  const handletagChange = (tags) => {
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsFormTouched(true);

    const allTagsValid = tags.every((tag) => regexEmail.test(tag));
    if (allTagsValid) {
      updateEvent({ ...event, email: tags });
    } else {
      toast.error("Enter valid email addresses");
    }
  };
  const handleDateClick = (arg) => {
    if (!authToken) {
      authLogin();
    } else {
      onChildData(arg.dateStr);
    }
  }

  const handleMapLocation = (location, origin = 'current+location') => {
    if (!location) {
      console.error('Destination location is required.');
      return;
    }
    try {
      const destination = encodeURIComponent(location);
      const encodedOrigin = encodeURIComponent(origin);
      const mapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${encodedOrigin}&destination=${destination}`;

      window.open(mapsUrl, '_blank');
    } catch (error) {
      console.error('Error opening Google Maps:', error);
    }
  };
  useEffect(() => {
    const list = subMeetingOptions.filter((opt) =>
      opt.label.toLowerCase().includes(subMeet.toLowerCase())
    );
    setFilterSubMeetingOpt(list);
  }, [subMeet]);

  const handleDropdownSelect = (data) => {
    const { label, value } = data;
    const theme = { label: label, value: value };
    updateEvent({ ...event, category: label, type: theme });
    setDropdownOpen(false); // Close the dropdown after selection (if needed)
  };
  const handleSingleCategory = (e) => {
    const value = e.target.value;
    // updateEvent({ ...event, category: value });
    const theme = { label: value, value: null };
    updateEvent({ ...event, category: value, type: theme });
    setIsFormTouched(true);
  }
  const handleSubMeetingSelect = (selectedItem) => {
    updateEvent({ ...event, subCategory: selectedItem.label });
    setSubMeet(selectedItem.label);
  }
  const handleSubMeetSerach = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setSubMeet(searchQuery);
    updateEvent({ ...event, subCategory: searchQuery });
    setIsFormTouched(true);
  }
  const handleCopy = (link) => {
    if (link) {
      navigator.clipboard.writeText(link)
        .then(() => {
          setTooltipText('Copied!');
          setTooltipOpen(true);

          setTimeout(() => {
            setTooltipText('Copy Link');
          }, 1500);
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    }
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (inputValue) {
        try {
          const response = await fetch(`https://api.mapbox.com/search/geocode/v6/forward?q=${inputValue}&proximity=ip&access_token=pk.eyJ1IjoicHJha2FzaG5pbiIsImEiOiJjbHk4aHp6MTgwajN1MmtzYjlnd3RkZzJlIn0.c0MhCeRKmQCZ6zIYCypLaw`);
          // console.log(response);
          if (response.ok) {
            const data = await response.json();
            setSuggestions(data.features);
            setLocationModal(true);
          }
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
        setLocationModal(false);
      }
    };

    const delayDebounceFn = setTimeout(() => {
      fetchSuggestions();
    }, 300); // Debounce input by 300ms

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  const handleData = (data) => {
    updateEvent({ ...event, location: data.properties.full_address });
    locationToggle();
    setSuggestions([]);
    setInputValue("");
  }
  const handleLocation = (e) => {
    const value = e.target.value;
    setInputValue(value);
    updateEvent({ ...event, location: value });
    setIsFormTouched(true);
  }
  const [reminderformData, setReminderFormData] = useState([]);

  const handleChange = (index, field, value) => {
    const newValues = [...reminderformData];
    newValues[index][field] = value;
    setReminderFormData(newValues);
  };

  const handleAddField = () => {
    setReminderFormData([...reminderformData, { remindertype: 'sms', remindertime: 'h', minutehour: '1' }]);
  };

  const handleRemoveField = (index) => {
    const newValues = reminderformData.filter((_, i) => i !== index);
    setReminderFormData(newValues);
  };
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isFormTouched) {
        const message = "You have unsaved changes. Are you sure you want to leave?";
        e.returnValue = message;
        return message;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormTouched]);


  return (
    <React.Fragment>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin, interactionPlugin]}
        events={mockEvents}
        eventClick={handleEventClick}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next title",
          center: null,
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        themeSystem="bootstrap5"
        dateClick={handleDateClick}
        contentHeight={"calc(100vh - 185px)"}
        eventContent={(e) => <EventView event={e} />}
        aspectRatio={3}
        editable={false}
        droppable={false}
        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        defaultTimedEventDuration={null}
        dayMaxEventRows={3}
        fixedWeekCount={false}
        views={{
          customMonth: {
            type: "dayGridMonth",
            dayMaxEvents: true,
          },
        }}
      />
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Delete Event</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this event? This action cannot be undone.
        </ModalBody>
        <ModalFooter>
          <span className="fs-14px fw-semibold text-danger me-2 pointer" onClick={toggleModal}>
            Cancel
          </span>
          <Button color="primary" onClick={() => {
            toggle();
            toggleModal();
            onDelete(event?.eventId);
          }}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalState} toggle={toggle}
        // className={`modal-${event?.users?.length > 0 && modalSize ? "xl" : secondModal ? "lg" : "md"}`}
        className={`modal-${secondModal && modalSize ? "xl" : secondModal || modalSize ? "lg" : "md"}`}
      >
        <ModalHeader className={`${event?.className} ${"fc-event-model-header"}`} toggle={toggle}>
          {event?.title === null ? "(No Title)" : event?.title}
        </ModalHeader>
        <ModalBody >
          <Row>
            {/* <div className="d-inline-flex g-3 "> */}
            <Col sm={`${secondModal && modalSize ? "4" : secondModal || modalSize ? "6" : "12"}`}>
              {/* <Col sm="auto"> */}
              <div className={`${secondModal || modalSize ? ""
                : "d-flex justify-content-between"}`}>
                <div>
                  <h6 className="overline-title m-0">Date</h6>
                  <div className="d-flex mb-2">
                    <p id="preview-event-start ">{getfullDate(event?.start)}</p>
                    {
                      getfullDate(event?.start) === getfullDate(event?.end)
                        ? ""
                        : (
                          <>
                            &nbsp; - &nbsp;
                            <p id="preview-event-start">{getfullDate(event?.end)}</p>
                          </>
                        )
                    }
                  </div>
                  <h6 className="overline-title m-0">Time</h6>
                  <div className="d-flex">
                    <p id="preview-event-end">{getfullTime(event?.start)}</p>
                    &nbsp; - &nbsp;
                    <p id="preview-event-end">{getfullTime(event?.end)}</p>
                  </div>
                </div>
                <div>
                  {
                    fileLoading ?
                      <ShimmerThumbnail height={150} width={260} rounded />
                      :
                      // noImg ?
                      //   ""
                      //   : 
                      (
                        <Fancybox>
                          {files4.length > 0 && files4.map((file) => (

                            <div className="me-auto" key={file?.name}
                              style={{ height: "150px" }}
                            >
                              <a
                                data-fancybox="gallery"
                                src={file?.preview}
                                className=" h-100 w-100"
                              >
                                <img
                                  alt={file?.name}
                                  src={file?.preview}
                                  style={{ height: "100%", width: "auto", objectFit: "cover" }}
                                />
                              </a>
                            </div>
                          )

                          )}
                        </Fancybox>
                      )
                  }
                </div>
              </div>
            </Col>
            {
              modalSize &&
              <Col sm={`${secondModal ? "4" : "6"}`}>
                {/* <Col sm="auto"> */}
                <div className="d-flex flex-row justify-content-between mb-2">
                  <div>
                    {
                      event?.category &&
                      <>
                        <h6 className="overline-title m-0">Category</h6>
                        <p id="preview-event-category">{event?.category}</p>
                      </>
                    }
                  </div>
                  <div>
                    {
                      event?.subCategory &&
                      <>
                        <h6 className="overline-title m-0">Meeting Held On</h6>
                        <p id="preview-event-category">{event?.subCategory}</p>
                      </>
                    }
                  </div>
                </div>
                {
                  event?.meetingLink &&
                  <>
                    <h6 className="overline-title m-0 align-center">{"Meeting Link"}
                      <div id="meetingLink" className="ms-2" onMouseUp={() => linkTip()}>
                        <Icon name="info" className="fs-5 pointer" />
                      </div>
                      <Tooltip
                        placement="top"
                        target="meetingLink"
                        isOpen={meetingLinkTip}
                        toggle={linkTip}
                      >
                        Connect to link
                      </Tooltip>
                    </h6>
                    <div className="d-flex flex-row justify-content-between">
                      <p id="preview-event-category " className="pointer"
                        onClick={() => window.location.href = event?.meetingLink}>
                        {shortenString(event?.meetingLink, 40)}
                      </p>
                      <div id="copyIcon" className="pointer">
                        <Icon name="copy" className="fs-5 px-1 fw-bold" onClick={() => handleCopy(event?.meetingLink)} />
                      </div>
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen}
                        target="copyIcon"
                        toggle={toggleTooltip}
                      >
                        {tooltipText}
                      </Tooltip>
                    </div>
                  </>
                }


                {
                  event?.description &&
                  <>
                    <h6 className="overline-title ">Description</h6>
                    <p id="preview-event-description" style={{ maxHeight: "100px", overflow: "auto" }}
                      className="text-break custom-scrollbar">
                      {event?.description}
                    </p>
                  </>
                }
                {
                  event?.location &&
                  <p id="preview-event-category"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleMapLocation(event?.location)}
                  >
                    <em class="icon ni ni-map-pin-fill fs-5 pe-1" />
                    {event?.location}
                  </p>
                }
              </Col>
            }

            {
              secondModal &&
              <Col sm={modalSize ? "4" : "6"} className="">
                {/* <C  ol sm="auto"> */}
                {event?.users?.length > 0 && (
                  <>
                    <div className="pb-2">
                      <h6 className="overline-title m-0">Organiser</h6>
                      <p id="preview-event-description" className="text-break">{event?.organizer}</p>
                      <h6 className="overline-title m-0">Invited Guest</h6>
                      <div className="overflow-auto custom-scrollbar" style={{ maxHeight: "140px" }}>
                        {event?.users?.map((item, index) => (
                          <p key={index} id="preview-event-description" className="text-break mb-0 border-bottom">
                            {item}
                          </p>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                {
                  JSON.parse(event?.reminder).length > 0 && (
                    <div className=" align-items-center">
                      <Col xs="auto" className="p-0">
                        <Icon name="alarm-alt" className="fs-4 pt-1" />
                      </Col>
                      <Col xs="auto" >
                        <div className="overflow-auto custom-scrollbar" style={{ maxHeight: "100px" }}>
                          {
                            JSON.parse(event?.reminder).map((item, index) => (
                              <p key={index} id="preview-event-description"
                                className="text-break pe-1 mb-0 border-bottom pointer">
                                {`You'll get a reminder ${item.minutehour} 
                                  ${item.remindertime === 'm' ? 'minutes' : 'hours'} 
                                  before via ${item?.remindertype?.toUpperCase()}.`}
                              </p>
                            ))
                          }
                        </div>
                      </Col>
                    </div>
                  )
                }
              </Col>
            }
            {/* </div> */}
          </Row>
          <ul className="d-flex justify-content-between gx-4 mt-3">
            {
              // Super Admin 
              userRole === "super_admin" || userRole === "admin" ?
                <>
                  <li>
                    <Button
                      color="primary"
                      onClick={() => {
                        toggle();
                        toggleEdit();
                      }}
                    >
                      Edit Event
                    </Button>
                  </li>
                  <li className="ms-auto">
                    <Button
                      color="primary"
                      onClick={toggleModal}
                    >
                      Delete
                    </Button>
                  </li>
                </>
                :
                //  organizer 
                organizer ?
                  (
                    <>
                      <li>
                        <Button
                          color="primary"
                          onClick={() => {
                            toggle();
                            toggleEdit();
                          }}
                        >
                          Edit Event
                        </Button>
                      </li>
                      <li className="ms-auto">
                        <Button
                          className="border-primary bg-transparent text-danger"
                          onClick={toggleModal}
                        >
                          Delete
                        </Button>
                      </li>
                    </>
                  )
                  : (
                    <>
                      {/* guest */}
                      <li className="ms-auto">
                        <Button
                          color="primary"
                          onClick={toggleModal}
                        >
                          Delete
                        </Button>
                      </li>
                    </>
                  )
            }
          </ul>
        </ModalBody>
      </Modal>
      <Modal isOpen={edit} toggle={toggleEdit} className="modal-lg">
        <ModalHeader toggle={toggleEdit}>Edit Event</ModalHeader>
        <ModalBody>
          <form className="form-validate is-alter" onSubmit={handleSubmit(handleFormSubmit)}>
            <Row className="gx-4 gy-3">
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="pen" className="fs-3" /> */}
                  <img src={form_logo_pen} alt="pen" height="24" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group">
                    {/* <label className="form-label" htmlFor="event-title">
                      Event Title<span className="required-star">*</span>
                    </label> */}
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="event-title"
                        // {...register("title", { required: true })}
                        className="form-control"
                        value={event.title}
                        onChange={(e) => {
                          updateEvent({ ...event, title: e.target.value });
                          setIsFormTouched(true);
                        }}
                      />
                      {/* {errors.title && <p className="invalid">This field is required</p>} */}
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="calendar-alt" className="fs-3" /> */}
                  <img src={form_logo_date} alt="pen" height="24" />
                </Col>
                <div className="d-flex flex-row flex-column flex-md-row  w-100">
                  <Col xs="auto" className="mb-1 me-0 me-md-1">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <DatePicker
                          minDate={new Date()}
                          selected={new Date(event.start)}
                          onChange={(date) => updateEvent({ ...event, start: setDateForPicker(date), startDate: date })}
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="auto" className="mb-1 me-0 me-md-1">
                    <div className="form-group">
                      <div className="form-control-wrap has-timepicker testp">
                        <DatePicker
                          selected={event.startTimeObj}
                          onChange={(date) => updateEvent({ ...event, startTimeObj: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Col>
                  <Icon name="arrow-right" className="fs-5 pt-1 pe-1 d-none d-md-block" />
                  <Col xs="auto" className="mb-1 me-0 me-md-1">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={new Date(event.end)}
                          onChange={(date) => updateEvent({ ...event, end: setDateForPicker(date), endDate: date })}
                          className="form-control date-picker"
                          minDate={event.startDate}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="auto">
                    <div className="form-group">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={event.endTimeObj}
                          onChange={(date) => {
                            if (event.startTimeObj.getTime() === date.getTime()) {
                              const endDateTime = new Date(date.getTime() + 60000); // Add 1 min 
                              updateEvent({ ...event, endTimeObj: endDateTime });
                            } else {
                              updateEvent({ ...event, endTimeObj: date });
                            }
                          }
                            // updateEvent({...event, endTimeObj: date })
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-2">
                  {/* <MdOutlineCategory className="fs-3" /> */}
                  <img src={form_logo_category} alt="pen" height="24" />
                </Col>
                <div className="d-flex flex-row flex-column flex-md-row  w-100">
                  <Col className="mb-1 me-0 me-md-1" xs="auto" md={`${event?.type?.label && event?.type?.label === "Meeting"
                    ? 3 : 12}`}>
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <UncontrolledDropdown style={{ width: "100%" }} isOpen={dropdownOpen} toggle={dropdowntoggle} direction="down">
                          <DropdownToggle caret className="p-0 border-0 w-100"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "inherit",
                              // Inline style to remove the hover effect
                              ':hover': {
                                backgroundColor: "transparent",
                                border: "none",
                                color: "inherit",
                              },
                            }}>
                            <input
                              className="form-control"
                              value={event?.category}
                              onChange={handleSingleCategory}
                              placeholder="Select Category"
                            />
                          </DropdownToggle>
                          <DropdownMenu
                            className="overflow-auto custom-scrollbar"
                            style={{
                              background: newTheme.skin === "dark" ? "#141c26" : "#fff",
                              height: "300px",
                            }}>
                            {eventOptions
                              .filter(item =>
                                item.label !== "Subscription Expire"
                                && item.label !== "Buy Subscription"
                                && item.label !== "Default"
                              )
                              .map((item, index) => (
                                <>
                                  <DropdownItem
                                    style={{
                                      background: newTheme.skin === "dark" ? "#141c26" : "#fff",
                                      color: "#8091a7"
                                    }}
                                    key={index}
                                    onClick={() => {
                                      handleDropdownSelect(item);
                                      dropdowntoggle();
                                      setIsFormTouched(true);
                                    }}
                                  >
                                    <GoDotFill style={{ color: `${item.color}` }} className="me-2" />
                                    {item.label}
                                  </DropdownItem>

                                </>
                              ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </Col>
                  {
                    event?.type?.label && event?.type?.label === "Meeting" ?
                      (
                        <Col sm="auto" md="3" className="mb-1 me-0 me-md-1">
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <UncontrolledDropdown style={{ width: "100%" }} isOpen={subDropdownOpen} toggle={toggleSubDropdown} direction="down">
                                <DropdownToggle caret className="p-0 border-0 w-100"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "inherit",
                                    // Inline style to remove the hover effect
                                    ':hover': {
                                      backgroundColor: "transparent",
                                      border: "none",
                                      color: "inherit",
                                    },
                                  }}>
                                  <input
                                    className="form-control"
                                    value={event.subCategory}
                                    onChange={handleSubMeetSerach}
                                    placeholder="Select"
                                  />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="overflow-auto custom-scrollbar"
                                  style={{ height: "300px" }}>
                                  {filterSubMeetingOpt.map((item, index) => (
                                    <DropdownItem
                                      style={{
                                        background: newTheme.skin === "dark" ? "#141c26" : "#fff",
                                        color: "#8091a7",
                                      }}
                                      key={index}
                                      onClick={() => {
                                        handleSubMeetingSelect(item);
                                        toggleSubDropdown();
                                        setIsFormTouched(true);
                                      }}
                                    >
                                      {item.img ? (
                                        <img
                                          src={item.img}
                                          alt={item.label}
                                          className="me-2"
                                          style={{ height: "20px", width: "20px" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {item.label}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </Col>
                      ) : ""
                  }
                  {
                    event?.type?.label && event?.type?.label === "Meeting" ?
                      (
                        <Col xs="auto" className="w-100">
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <input
                                placeholder="Link"
                                type="text"
                                id="link"
                                value={event.meetingLink}
                                onChange={(e) => {
                                  updateEvent({ ...event, meetingLink: e.target.value });
                                  setIsFormTouched(true);
                                }}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </Col>
                      ) : ("")
                  }
                </div>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-1">
                  {/* <MdOutlineEditNote className="fs-1" /> */}
                  <img src={form_logo_description} alt="pen" height="28" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group" style={{ marginLeft: "4px" }}>
                    <div className="form-control-wrap">
                      <textarea
                        className="form-control"
                        id="event-description"
                        value={event.description}
                        onChange={(e) => {
                          updateEvent({ ...event, description: e.target.value });
                          setIsFormTouched(true);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="map-pin" className="fs-3" /> */}
                  <img src={form_logo_location} alt="pen" height="26" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group" >
                    <div className="form-control-wrap" id="Location">
                      <UncontrolledDropdown style={{ width: "100%" }}>
                        <DropdownToggle caret className="p-0 border-0 w-100 "
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            color: "inherit",
                            // Inline style to remove the hover effect
                            ':hover': {
                              backgroundColor: "transparent",
                              border: "none",
                              color: "inherit",
                            },
                          }}
                        >
                          <input
                            className="form-control "
                            value={event?.location}
                            onChange={handleLocation}
                            placeholder="Add Location"
                          />
                        </DropdownToggle>
                        <DropdownMenu >
                          {inputValue && suggestions.length > 0 && (
                            <div className="bg-white shadow border d-flex flex-column rounded-2">
                              <div className="d-flex flex-column">
                                <div className="px-2">
                                  {suggestions.slice(0, 4).map((suggestion, index) => (
                                    <div
                                      key={index}
                                      onClick={() => handleData(suggestion)}
                                      style={{ paddingTop: "10px", paddingBottom: "10px", cursor: "pointer" }}
                                      className='border-bottom'
                                    >
                                      <p
                                        className="text-primary d-flex cursor-pointer m-0 flex-column ms-2"
                                        style={{ gap: "0.425rem", lineHeight: "16px" }}
                                      >
                                        <span className='fw-bold text-dark'>{suggestion.properties.name}</span>
                                        <small className='fw-light text-dark'>{suggestion.properties.full_address}</small>
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="users" className="fs-3" /> */}
                  <img src={form_logo_guests} alt="pen" height="26" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group">
                    <div className="form-control-wrap ">
                      <RSelect
                        placeholder="Add Guests"
                        options={UserListRef}
                        isMulti
                        defaultValue={
                          Array.isArray(event.users)
                            ? event.users.map((username) => ({ value: username, label: username }))
                            : []
                        }
                        onChange={(selectedOption) => {
                          const inputValue = selectedOption.map((option) => option.value);
                          updateEvent({ ...event, users: inputValue });
                          setIsFormTouched(true);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="mail" className="fs-3" /> */}
                  <img src={form_logo_mail} alt="pen" height="26" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <TagsInput
                        className="form-control p-0 w-100"
                        inputProps={{
                          className: 'w-100 react-tagsinput-input tagsinput-color secondary-text ',
                          placeholder: 'Invite Via Mail',
                        }}
                        value={Array.isArray(event.email) ? event.email : [event.email]}
                        onChange={handletagChange}
                      />

                    </div>
                  </div>
                </Col>
              </div>

              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="img" className="fs-3" /> */}
                  <img src={form_logo_img} alt="pen" height="25" />
                </Col>
                <Col xs="auto" className={`${files4.length > 0 && "w-100"}`}>
                  <Dropzone
                    onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setFiles4)}
                    accept={[".jpg", ".png", ".svg"]}
                    maxFiles={1}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="d-flex form-control p-0">
                        <div {...getRootProps()} className="dropzone upload-zone dz-clickable p-0 border-0"
                          style={{ minHeight: "0", background: newTheme.skin === "dark" ? "#0d0c0c" : "#fcfafa" }}>
                          <input {...getInputProps()} />

                          {files4.length === 0 && (
                            <div className="dz-message m-0 mx-1 my-1">
                              <span className="dz-message-text">Drag And Drop File</span>
                            </div>
                          )}
                          {files4.map((file, index) => (
                            <div
                              key={index}
                              className="dz-preview dz-processing dz-image-preview dz-error dz-complete me-auto"
                            >
                              <div className="d-flex flex-row align-items-center">
                                <div className="dz-image">
                                  <img src={file?.preview} alt="preview" />
                                </div>
                                {
                                  file?.size &&
                                  <div className="ps-2">
                                    <p className="m-0">{shortenString(file?.name, 30)}</p>
                                    <p>Size : {formatFileSize(file?.size)}</p>
                                  </div>
                                }
                              </div>
                            </div>
                          ))}
                        </div>
                        {files4.length > 0 && (
                          <div className="align-center ms-auto pe-1">
                            {files4.map((file, index) => (
                              <div
                                key={file.name}
                                onClick={() => setFiles4([])}
                                aria-label="Remove"
                                className="align-center"
                              >
                                <Icon
                                  name="cross"
                                  className="fs-4 pointer form-control bg-transparent border-0 cross-btn"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </section>
                    )}
                  </Dropzone>
                </Col>
              </div>

              <div className="d-inline-flex w-100">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="alarm-alt" className="fs-3" /> */}
                  <img src={form_logo_remainder} alt="pen" height="28" className="mt-1" />
                </Col>
                <div className="d-flex flex-column w-100">
                  <Col xs="auto" className="">
                    {
                      reminderformData?.map((value, index) => (
                        <div key={index} className="d-flex flex-row flex-column flex-md-row w-100 pb-1">
                          <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <select
                                  className="form-control"
                                  value={value?.remindertype}
                                  onChange={(e) => {
                                    handleChange(index, 'remindertype', e.target.value);
                                    setIsFormTouched(true);
                                  }}
                                >
                                  <option value="sms">SMS</option>
                                  <option value="email">Email</option>
                                </select>
                              </div>
                            </div>
                          </Col>
                          <Col sm="1" className="mb-1 me-1 me-xs-0">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <label className="form-control border-0">Before</label>
                              </div>
                            </div>
                          </Col>
                          <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <input
                                  className="form-control"
                                  type="number"
                                  value={value?.minutehour}
                                  onChange={(e) => {
                                    handleChange(index, 'minutehour', e.target.value);
                                    setIsFormTouched(true);
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <select
                                  className="form-control"
                                  value={value?.remindertime}
                                  onChange={(e) => {
                                    handleChange(index, 'remindertime', e.target.value);
                                    setIsFormTouched(true);
                                  }}
                                >
                                  <option value="h">Hours</option>
                                  <option value="m">Minutes</option>
                                </select>
                              </div>
                            </div>
                          </Col>
                          <Col xs="auto" className="">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <Icon
                                  name="cross"
                                  className="fs-4 pointer form-control bg-transparent border-0 cross-btn"
                                  onClick={() => handleRemoveField(index)} // Remove field on icon click
                                />
                              </div>
                            </div>
                          </Col>
                        </div>
                      ))
                    }
                  </Col>
                  <Col xs="12" sm="6" md="2">
                    <label
                      className="btn-color"
                      onClick={handleAddField}
                    >
                      Add Reminder
                    </label>
                  </Col>
                </div>
              </div>

              <Col size="12">
                <ul className="d-flex justify-content-between gx-4 mt-1">
                  <li>
                    <Button type="submit" color="primary" disabled={loading}>
                      {loading ? (
                        <span className="align-center">
                          <Spinner size="sm" color="light" /> Loading...
                        </span>
                      ) : (
                        <span>Update Event</span>
                      )}
                    </Button>
                  </li>
                  <li>
                    <Button color="primary" onClick={toggleEdit}>
                      Discard
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment >
  );
};

export default CalenderApp;