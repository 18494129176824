import React, { useState, useEffect } from "react";
import classNames from "classnames";
import User from "./dropdown/user/User";
import { useTheme } from "../provider/Theme";
import AppDropdown from "./dropdown/app/App";
import { calendar, CalenderA, CalenderB } from "../../images/applogos";
import { Icon } from "../../components/Component";
import Notification from "./dropdown/notification/Notification";
import { authLogin, getCookie } from "../../utils/Utils";
import Cookies from 'js-cookie';
import SpeechMic from "../../utils/VoiceComponent";

const Header = ({ fixed, className }) => {
  const theme = useTheme();

  const [authenticated, setAuthenticated] = useState(false); // State to manage user authentication status

  useEffect(() => {
    const isAuthenticated = !!getCookie("authToken"); // Example: Check if a user token exists in cookies
    setAuthenticated(isAuthenticated);
  }, []);

  const headerClass = classNames({
    "nk-header": true,
    // "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  // const location = useLocation();
  const [fullScreenOn, setFullScreenOn] = useState(false);
  const toggleFullScreen = () => {
    setFullScreenOn(!fullScreenOn);
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      // Enter fullscreen mode
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  // State for time and date
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);

      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const day = days[now.getDay()];
      const date = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();

      setDate(`${day}, ${month}/${date}/${year}`);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSignIn = () => {
    authLogin();
  };

  return (
    <>
      <section className="section-header">
        <div className={`${headerClass} `}>
          <div className="container-fluid g-0 g-lg-5">
            <div className="nk-header-wrap align-items-center d-flex py-1">
              <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <div className="d-none d-sm-block">
                    <img alt="header-logo" height="40px" width="110" src={theme.skin === "dark" ? CalenderB : CalenderA} />
                  </div>
                  <div className="d-sm-none">
                    <img height="28px" src={calendar} />
                  </div>
                </div>


                <div className="form-control-wrap ms-md-4 ms-1  header-search ">
                  <div className="form-icon form-icon-left">
                    <Icon name="search" />
                  </div>
                  <input className="form-control bg-transparent" type="text" id="appsearch" placeholder="Search" style={{ borderRadius: '24px' }}
                  // value={searchTerm} onChange={handleSearchChange} onKeyPress={handleKeyPress} 
                  />
                  <div className="form-icon form-icon-right rounded-5">
                    <SpeechMic />
                  </div>
                </div>
              </div>

              <div className=" ms-auto d-flex d-none d-sm-block">
                <div className="d-flex flex-row">
                  <div className="lh-17px pe-3">
                    <p className="mb-0 fw-bold text-blue">{time}</p>
                    <p className="fs-9px mb-0">{date}</p>
                  </div>
                  <div>
                    <a href="https://calendar.silocloud.io/">
                      <Icon name="calender-date" className="head-icon" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="nk-header-tools align-items-center py-0">
                <ul className="nk-quick-nav">
                  <li className="mt-1 d-none d-md-block">
                    {fullScreenOn ? (
                      <Icon name="minimize" className="pointer fs-4" onClick={toggleFullScreen} />
                    ) : (
                      <Icon name="maximize" className="pointer fs-4" onClick={toggleFullScreen} />
                    )}
                  </li>
                  <li className="notification-dropdown">
                    <Notification />
                  </li>
                  <li className="notification-dropdown">
                    <AppDropdown />
                  </li>

                  {!authenticated ? ( // If user is not authenticated, display sign-in button
                    <li className="d-flex justify-content-end align-items-center ">
                      <button className="btn sign-in-btn h-25" onClick={handleSignIn}>
                        Sign In
                      </button>
                    </li>
                  ) : (
                    <li className="user-dropdown d-flex">
                      <User />
                    </li>
                  )}
                  {/* <li className="user-dropdown">{authenticated ? <User /> : ""}</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
