import { BlueJeans, Cisco_Webex, Google_Meet, GoToMeeting, Jitsi_Meet, Microsoft_Teams, RingCentral, silo_connect, silotalk_logo, Skype, Slack, tv_logo, Zoom } from "../../../images/applogos";

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0");
var yyyy = today.getFullYear();

var tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);
/*
var t_dd = String(tomorrow.getDate()).padStart(2, "0");
var t_mm = String(tomorrow.getMonth() + 1).padStart(2, "0");
var t_yyyy = tomorrow.getFullYear(); 
*/

var yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);
var y_dd = String(yesterday.getDate()).padStart(2, "0");
var y_mm = String(yesterday.getMonth() + 1).padStart(2, "0");
var y_yyyy = yesterday.getFullYear();

var YM = yyyy + "-" + mm;
var YESTERDAY = y_yyyy + "-" + y_mm + "-" + y_dd;
var TODAY = yyyy + "-" + mm + "-" + dd;

var month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const returnDate = (date) => {
  if (date !== undefined) {
    const dateSection = date.split("-");
    let newDate = dateSection[2] + " " + month[Number(dateSection[1]) - 1] + " " + dateSection[0];
    return newDate;
  }
};
function getLocalTimeZoneOffset() {
  // Get the local timezone offset in minutes from UTC
  return new Date().getTimezoneOffset();
}

// Example usage:

export const formattedUSDate = (dateString) => {
  let date = new Date(dateString);
  date = new Date(date.getTime() - getLocalTimeZoneOffset() * 60000);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // Use 12-hour clock
    timeZone: "UTC", // Set the timezone if needed
  };
  const offsetInMinutes = getLocalTimeZoneOffset();
  return date.toLocaleString(undefined, options);
};
export const getfullDate = (dateString) => {
  let date = new Date(dateString);
  date = new Date(date.getTime() - getLocalTimeZoneOffset() * 60000);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: true, // Use 12-hour clock
    timeZone: "UTC", // Set the timezone if needed
  };
  const offsetInMinutes = getLocalTimeZoneOffset();
  return date.toLocaleString(undefined, options);
};
export const getfullTime = (dateString) => {
  let date = new Date(dateString);
  date = new Date(date.getTime() - getLocalTimeZoneOffset() * 60000);
  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // Use 12-hour clock
    timeZone: "UTC", // Set the timezone if needed
  };
  const offsetInMinutes = getLocalTimeZoneOffset();
  return date.toLocaleString(undefined, options);
};
export const events = [];

export const eventOptions = [
  // { value: "fc-event-primary", color: "#6576ff", type: "Com", label: "Company", catname: "company" },
  { value: "fc-event-warning", color: "#0392CE", type: "normal", label: "Meeting" },
  { value: "fc-event-orange", color: "#FFA500", type: "normal", label: "Default" },
  { value: "fc-event-indigo", color: "#6666CC", type: "normal", label: "Seminars" },
  { value: "fc-event-info", color: "#Cb5499", type: "normal", label: "Conferences" },
  { value: "fc-event-danger", color: "#F0824F", type: "normal", label: "Business dinners" },
  { value: "fc-event-pink", color: "#Ff3399", type: "normal", label: "Private" },
  { value: "fc-event-primary", color: " #0247FC", type: "normal", label: "Auctions" },
  { value: "fc-event-success-dim", color: "#3C02A6", type: "normal", label: "Networking Events" },
  { value: "fc-event-info-dim", color: "#B9D11E ", type: "normal", label: "Product Launches" },
  { value: "fc-event-warning-dim", color: "#FD9A01 ", type: "normal", label: "Fundraising" },
  { value: "fc-event-danger-dim", color: "#A7184D", type: "normal", label: "Sponsored" },
  { value: "fc-event-pink-dim", color: "#00CCFF", type: "normal", label: "Sports events" },
  { value: "fc-event-success", color: "#1ee0ac", type: "normal", label: "Buy Subscription" },
  { value: "fc-event-danger", color: "#e85347", type: "normal", label: "Subscription Expire" },
];

export const subMeetingOptions = [
  { value: "fc-event-warning", type: "meeting", img: silotalk_logo, label: "Silo Talk" },
  { value: "fc-event-warning", type: "meeting", img: silo_connect, label: "Silo Connect" },
  { value: "fc-event-warning", type: "meeting", img: RingCentral, label: "RingCentral" },
  { value: "fc-event-warning", type: "meeting", img: Google_Meet, label: "Google Meet" },
  { value: "fc-event-warning", type: "meeting", img: Zoom, label: "Zoom" },
  { value: "fc-event-warning", type: "meeting", img: Microsoft_Teams, label: "Microsoft Teams" },
  { value: "fc-event-warning", type: "meeting", img: Cisco_Webex, label: "Cisco Webex" },
  { value: "fc-event-warning", type: "meeting", img: Skype, label: "Skype" },
  { value: "fc-event-warning", type: "meeting", img: Jitsi_Meet, label: "Jitsi Meet" },
  { value: "fc-event-warning", type: "meeting", img: GoToMeeting, label: "GoToMeeting" },
  { value: "fc-event-warning", type: "meeting", img: BlueJeans, label: "BlueJeans" },
  { value: "fc-event-warning", type: "meeting", img: Slack, label: "Slack" },
]

export const getCategoryValueByLabel = (label) => {
  const option = eventOptions.find((option) => option.label === label);
  return option ? option.value : null;
};
export const getCategory = (value, by = "value") => {
  const option = eventOptions.find((option) => option[by] === value);
  return option ? option : null;
};

export const ReminderTypeOtions = [
  { value: "sms", label: "SMS" },
  { value: "email", label: "Email" },
];
export const ReminderTimeOtions = [
  { value: "h", label: "Hours" },
  { value: "m", label: "Minutes" },
];
