import React, { useState, useRef, useEffect } from "react";
import "../../css/common.css";
import "./footer.css";
import {
  d_plus_logo,
  Homelightlogo,
} from "../../images/applogos";

import {
  Popover,
  PopoverBody,
  Progress,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
}
  from "reactstrap";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { useTheme } from "../provider/Theme";
import { Icon } from "../../components/Component";
import Skeleton from "react-loading-skeleton";
import useStorageUsage from "../../utils/useStorageUsage";
import { postRequestData } from "../../utils/api-services";
import { getCookie } from "../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { pen_logo } from './../../images/applogos/index';
import { ShimmerCircularImage } from "react-shimmer-effects";
import { Tooltip } from "@mui/material";

const Footer = () => {

  const navigate = useNavigate();

  const { skin, sidebarMobile } = useTheme();
  const popoverRef = useRef();
  const popoverTargetRef = useRef();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [loadingApp, setLoadingApp] = useState(null);
  const [appLoading, setAppLoading] = useState(false);
  const togglePopover = () => setPopoverOpen(!popoverOpen);
  const authToken = getCookie("authToken");

  const { storageDetails, loading } = useStorageUsage();

  const [footerApps, setFooterApps] = useState([]);
  const [draggedIconIndex, setDraggedIconIndex] = useState(null);

  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      popoverTargetRef.current &&
      !popoverTargetRef.current.contains(event.target) &&
      !event.target.closest(".footerlogosPop-container")
    ) {
      setPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleImageInFooter = async (image, key, value) => {
    setLoadingApp(key);

    if (!authToken) {
      toast.error("Please login first");
      setLoadingApp(null);
      return;
    }

    // const Data = new FormData();
    // Data.append("columnValue", value);
    // Data.append("action", "update");
    // Data.append("column", "apps");
    // Data.append("columnKey", key);
    const Data = {
      "columnValue": value,
      "action": "update",
      "column": "apps",
      "columnKey": key
    };
    try {

      const addApps = await postRequestData(Data, "api/v1/public/frontend-settings");
      if (addApps.status) {
        setFooterApps((prevFooterApps) =>
          prevFooterApps.map((app) => (app.key === key ? { ...app, footer_visibility: value } : app))
        );
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error updating footer app visibility:", error);
    } finally {
      setLoadingApp(null);
    }
  };

  useEffect(() => {
    fetchFooterApps();
  }, []);

  const fetchFooterApps = async () => {
    try {
      // const Data = new FormData();
      // Data.append("action", "fetch");
      const Data = {
        action: "fetch",
      };
      const response = await postRequestData(Data, "api/v1/public/frontend-settings");
      if (response.status) {
        setFooterApps(response?.data?.frontend_settings?.apps.map((app) => ({
          ...app,
          imgSrc: `https://api.silocloud.io/${app.imgSrc}`,  // Directly use the URL here
          link: app.title === "Talk"
            ? `${app.link}/token-login/${authToken}`
            : `${app.link}`
        })));
        // setFooterApps((prevFooterApps) =>
        //   prevFooterApps.map((app) => {
        //     let tempApp = response?.data?.frontend_settings?.apps[app.key - 1] || null;
        //     return tempApp ? { ...app, footer_visibility: tempApp.footer_visibility } : app;
        //   })
        // );
      }
    } catch (error) {
      console.error("Error fetching footer apps:", error);
    }
  };

  // Handle when dragging starts
  const handleDragStart = (index) => {
    setDraggedIconIndex(index); // Store the index of the dragged item
    // console.log(index)
  };

  // Handle when dragged item is over another
  const handleDragOver = (e, index) => {
    e.preventDefault();
    // Get the element being dragged over
    const draggedOverItem = document.getElementById(`footer-logo-${index}`);


    draggedOverItem.classList.add("dragging");


    footerApps.forEach((_, i) => {
      if (i !== index) {
        const item = document.getElementById(`footer-logo-${i}`);
        if (item) {
          item.classList.remove("dragging");
        }
      }
    });
  };

  // Handle when item is dropped
  const handleDrop = (index) => {
    const draggedElement = document.getElementById(`footer-logo-${draggedIconIndex}`);
    const droppedElement = document.getElementById(`footer-logo-${index}`);

    // Remove border from both elements
    draggedElement.classList.remove("dragging");
    droppedElement.classList.remove("dragging");
    // console.log(index);
    const newIcons = [...footerApps];
    const [draggedIcon] = newIcons.splice(draggedIconIndex, 1); // Remove the dragged icon
    newIcons.splice(index, 0, draggedIcon); // Insert it at the new position
    // console.log(newIcons);
    dragDropApps(newIcons);

    setFooterApps(newIcons); // Update state with new icon order
    setDraggedIconIndex(null); // Clear dragged index
  };

  const handleDragLeave = (index) => {
    const draggedOverItem = document.getElementById(`footer-logo-${index}`);

    // Remove border from the dragged-over item
    draggedOverItem.classList.remove("dragging");
  };

  const dragDropApps = async (data) => {
    setAppLoading(true);
    if (!authToken) {
      toast.error("Please login first");
      setLoadingApp(null);
      return;
    }

    const searchStr = "https://api.silocloud.io/";

    // Function to count occurrences of the search string
    const countOccurrences = (str, subStr) => (str.match(new RegExp(subStr, "g")) || []).length;

    const updatedArr = data.map((obj, index) => {
      let imgSrc = obj.imgSrc;
      let sequence_no = String(index + 1);

      // If sequence_no doesn't match, update it
      if (obj.sequence_no !== sequence_no) {
        obj.sequence_no = sequence_no;
      }

      // Count occurrences of the search string in `imgSrc`
      const occurrenceCount = countOccurrences(imgSrc, searchStr);

      // If the substring appears, remove all occurrences
      if (occurrenceCount > 0) {
        imgSrc = imgSrc.replaceAll(searchStr, "");
      }

      // Assign the modified imgSrc back to the object
      obj.imgSrc = imgSrc;

      // Special handling for objects with the title "talk"
      if (obj.title === "talk") {
        obj.link = "https://silotalk.com";
      }

      return obj;
    });

    const Data = {
      action: "rearrange",
      apps: updatedArr, // Use the updated array with modified `imgSrc`
    };

    try {
      const response = await postRequestData(Data, "api/v1/public/frontend-settings");
      if (response.status) {
        setFooterApps(response?.data?.frontend_settings?.apps.map((app) => ({
          ...app,
          imgSrc: `https://api.silocloud.io/${app.imgSrc}`,  // Directly use the URL here
          link: app.title === "Talk"
            ? `${app.link}/token-login/${authToken}`
            : `${app.link}`
        })));
      }
    } catch (error) {
      console.error("Error during drag & drop footer app:", error);
    } finally {
      setAppLoading(false);
    }
  };

  return (
    <>
      <section className="section-footer w-100 position-fixed bottom-0 start-0 z-3 py-1">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-2 col-lg-3 px-0 px-lg-3 my-auto">
              <div className="d-flex align-items-center">
                <a href="https://silocloud.io/" className="home_icon">
                  <img className="homeicon" src={Homelightlogo} alt="" />
                </a>
                {
                  authToken ?
                    (<div className="storage-block d-none d-lg-block rounded-3 px-2 py-1 ms-1">
                      {" "}
                      {/* Hide on mobile */}
                      <a href="https://storage.silocloud.io/">
                        <div className="d-flex align-items-center mb-1">
                          <Icon name="db-fill" className="fs-3" />
                          {
                            loading ?
                              <Skeleton width={100} height={8} baseColor="#c5bfe0" />
                              :
                              <p className={`fs-9px mb-0 ms-1 opacity-75 ${skin === "light" ? "text-dark" : ""}`}>
                                Cloud Storage {storageDetails.usedStorageText}/{storageDetails.planStorageText} Used
                              </p>
                          }
                        </div>
                        <div className="">
                          <Progress
                            value={storageDetails.percentageUsed === 0
                              ? storageDetails.percentageUsed + 1
                              : storageDetails.percentageUsed}
                            color="info"
                            className="progress-storage" />
                        </div>
                      </a>
                    </div>)
                    :
                    ""
                }

              </div>
            </div>

            <div className="col-9 col-lg-7 my-auto responsive-mobile d-none d-md-block">
              <div className="d-flex justify-content-center align-items-center">
                {footerApps.map((image, index) => {
                  let tempfooterApp = image.footer_visibility == "1" || !image.popOver;
                  return (
                    tempfooterApp && (
                      <div key={index} id={`footer-logo-${index}`} className="text-center me-1 me-lg-1 pointer"
                        onClick={() => {
                          if (image.useNavigate) {
                            navigate("/apps");
                          } else {
                            window.location.href = image.link;
                          }
                        }}
                        draggable
                        onDragStart={() => handleDragStart(index)} // Start dragging
                        onDragOver={(e) => handleDragOver(e, index)} // Allow drag over this element
                        onDragLeave={() => handleDragLeave(index)} // Reset the scaling on drag leave
                        onDrop={() => handleDrop(index)} // Handle dropping here
                      >
                        {!image.imgSrc ? (
                          <Spinner size="sm" className="spinner-md" />
                        ) :
                          appLoading ? (
                            <ShimmerCircularImage size={44} className="m-0" />
                          ) : (
                            <Tooltip key={index} title={image.title} arrow>
                              <div
                                className={`position-relative footer-apps ${popoverOpen ? "footerlogosPop-container" : ""}`}
                              >
                                <img
                                  src={image.imgSrc}
                                  id={`footer-logo-${index}`}
                                  alt={`Footer Logo ${index}`}
                                  className="footerlogos"
                                  height={"28px"}
                                />
                                {image.showIcon ? (
                                  <div
                                    className={`plus-minus-icon-${image.key} ${popoverOpen ? "minus-icon" : ""}`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleImageInFooter(image, image.key, "2");
                                    }}
                                  >
                                    <FaMinusCircle className="plus-minus-icon" />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Tooltip>
                          )}

                      </div>
                    )
                  );
                })}

                <div className="bg-icon-plus ms-1" ref={popoverTargetRef}>
                  <img src={popoverOpen ? pen_logo : d_plus_logo} alt="" height="15" id="PopoverTop" onClick={togglePopover} />
                </div>

                <Popover
                  placement="top"
                  isOpen={popoverOpen}
                  target="PopoverTop"
                  toggle={togglePopover}
                  innerRef={popoverRef}
                  className="popover-images"
                >
                  <PopoverBody>
                    {footerApps.length === 0 ||
                      footerApps.every((image) => !(image.popOver && image.footer_visibility == 2)) ? (
                      <div className="text-center">
                        <img src={d_plus_logo} alt="default" height="28px" />
                        <p className="fs-9px mt-1 opacity-75 mb-0">Apps</p>
                      </div>
                    ) : (
                      footerApps.map((image, index) => {
                        return (
                          image.popOver &&
                          image.footer_visibility == "2" && (
                            <div
                              key={index}
                              className="footerlogosPop-container text-center pointer"
                              onClick={() => {
                                toggleImageInFooter(image, image.key, 1);
                              }}
                            >
                              {loadingApp === image.key ? (
                                <Spinner size="sm" className="mt-3 spinner-md" />
                              ) : (
                                <>
                                  <div className="position-relative footer-apps">
                                    <img
                                      src={image.imgSrc}
                                      alt={`Popover Logo ${index}`}
                                      className="footerlogosPop"
                                      style={image.style}
                                      height={"28px"}
                                    />
                                    <div className={` plus-minus-icon-${image.key}`}>
                                      <FaPlusCircle
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleImageInFooter(image, image.key, 1);
                                        }}
                                        className="plus-minus-icon"
                                      />
                                    </div>
                                  </div>
                                  <p className="fs-9px mt-1 opacity-75 mb-0">{image.title}</p>
                                </>
                              )}
                            </div>
                          )
                        );
                      })
                    )}
                  </PopoverBody>
                </Popover>
              </div>
            </div>


            <div className="col-1 col-lg-2 px-0 px-lg-3 mobile-view  my-auto d-none d-md-block">
              <div className="d-lg-none" >
                <UncontrolledDropdown direction="up">
                  <DropdownToggle className="btn-action p-0 border-0 bg-transparent" color="primary">
                    <span>
                      <Icon name="menu" className="theme-menu" />
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <ul className="link-list-opt">
                      {/* <li className="py-2">
                        {sidebarMobile && <TalkComponent />}
                      </li> */}
                      <li className="py-2">
                        <div className="d-flex align-items-center footer-bottom justify-content-center ">
                          {/* <Icon name="power" className="text-blue-color fs-10px  rounded-2" id="power-button"></Icon> */}
                          <a href="https://silocloud.com/" className="mb-0 fs-9px">
                            About
                          </a>
                          <a href="https://silocloud.io/privacy-policy" className="mb-0 fs-9px">
                            Privacy
                          </a>
                          <a href="https://silocloud.io/terms-condition" className="mb-0 fs-9px">
                            Terms
                          </a>
                          {/* <img src={img} alt="Settings Icon" className="me-0" height="15px" onClick={toggle} /> */}
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="storage-block rounded-3 px-2 py-1 ms-1">
                          <a href="https://storage.silocloud.io/">
                            <div className="d-flex align-items-center">
                              <Icon name="db-fill" className="fs-3" />
                              {!loading ? (
                                <p className="fs-9px mb-0 ms-1 opacity-75">
                                  Cloud Storage {storageDetails.usedStorageText}/{storageDetails.planStorageText} Used
                                </p>
                              ) : (
                                <Skeleton width={100} height={8} baseColor="#c5bfe0" />
                              )}
                            </div>
                            <div className="">
                              <Progress
                                value={storageDetails.percentageUsed}
                                color="info"
                                className="progress-storage"
                              />
                            </div>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="d-none d-lg-block">
                {/* <TalkComponent /> */}
                <div className="d-flex align-items-center footer-bottom justify-content-center justify-content-lg-end">
                  {/* <Icon name="power" className="text-blue-color fs-10px  rounded-2" id="power-button"></Icon> */}
                  <a href="https://silocloud.com/" className="mb-0 fs-9px">
                    About
                  </a>
                  <a href="https://silocloud.io/privacy-policy" className="mb-0 fs-9px">
                    Privacy
                  </a>
                  <a href="https://silocloud.io/terms-condition" className="mb-0 fs-9px">
                    Terms
                  </a>

                  {/* <img src={img} alt="Settings Icon" className="ms-2" height="15px" onClick={toggle} /> */}
                </div>
              </div>
            </div>

          </div>
        </div>
      </section >

    </>
  );
};

export default Footer;
