import React, { createContext, useState } from 'react';

export const contextDataProvider = createContext();

export const ContextProvider = ({ children }) => {
    const [contextData, setContextData] = useState(null);

    return (
        <contextDataProvider.Provider value={{ contextData, setContextData }}>
            {children}
        </contextDataProvider.Provider>
    );
}
