import Cookies from "js-cookie";
export async function deleteCalendarEventApi(eventId) {
  try {
    let formData = new FormData();
    formData.append("id", eventId);
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/calendar-events/delete`, {
      method: "POST",
      headers: {
        authToken: Cookies.get("authToken"),
      },
      body: formData,
    });
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    return { status: false, message: error.message };
  }
}
export async function updateCalendarEventApi(eventId, eventData) {
  try {
    let formData = new FormData();
    formData.append("id", eventId);
    formData.append("event_title", eventData.title);
    formData.append("start_date_time", eventData.start);

    formData.append("end_date_time", eventData.end);
    formData.append("event_description", eventData.description);
    formData.append("category", eventData?.category);
    formData.append("subCategory", eventData?.subCategory);
    formData.append("meetingLink", eventData?.meetingLink);
    formData.append("reminder", eventData.reminder);
    formData.append("invite_by_email", eventData.email);
    formData.append("invite_by_username", eventData.users);
    formData.append("location", eventData.location);
    if (eventData.event_image?.type) formData.append("event_attachment", eventData.event_image);

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/calendar-events/update`, {
      method: "POST",
      headers: {
        authToken: Cookies.get("authToken"),
        Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      body: formData,
    });

    let uploadFilesResponse = await response.json();
    return uploadFilesResponse;
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export async function fetchUser(inputValue) {
  let formData = new FormData();


  formData.append('search', inputValue);

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/calendar-events/users`, {
      method: "POST",
      headers: {
        authToken: Cookies.get("authToken"),
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const fetchResponse = await response.json();
    return fetchResponse;
  } catch (error) {
    console.error('Error fetching user:', error);
    return { status: false, message: error.message };
  }
}
