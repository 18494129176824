import React, { useState, useEffect, useContext } from "react";
import ContentAlt from "../../../layout/content/ContentAlt";
import Head from "../../../layout/head/Head";
import CalenderApp from "../../../components/partials/calender/Calender";
import DatePicker from "react-datepicker";
import "../../../../src/css/calender-custom.css";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Spinner,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import {
  Block,
  Col,
  Icon,
  PreviewAltCard,
  Row,
  RSelect,
} from "../../../components/Component";

import {
  eventOptions,
  events,
  getCategory,
  getCategoryValueByLabel,
  subMeetingOptions,
} from "../../../components/partials/calender/CalenderData";
import { useForm } from "react-hook-form";
import { formatFileSize, MakeApiCall, shortenString } from "../../../utils/Utils";
import {
  checkReminder,
  getCombinedDate,
  getCurrentDateTimeInFormat,
  handleDropChange,
} from "../../../utils/helper";
import useUserList from "../../../utils/useUserList";
import { deleteCalendarEventApi, updateCalendarEventApi } from "./EventApi";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { GoDotFill } from 'react-icons/go';
import { useTheme } from "../../../layout/provider/Theme";
import { TriggerBtnContext } from "../../../App";
import useStorageUsage from "../../../utils/useStorageUsage";
import { MdOutlineEditNote, MdOutlineCategory } from "react-icons/md";
import { contextDataProvider } from "../../../utils/contextData";
import { form_logo_category, form_logo_date, form_logo_description, form_logo_guests, form_logo_img, form_logo_location, form_logo_mail, form_logo_pen, form_logo_remainder } from "../../../images/applogos";

const Calender = () => {

  const newTheme = useTheme();
  const { contextData, setContextData } = useContext(contextDataProvider);
  const [modal, setModal] = useState(false);
  const [mockEvents, updateEvent] = useState(events);
  const [modalSm, setModalSm] = useState(false);
  const toggleSm = () => setModalSm(!modalSm);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdowntoggle = () => setDropdownOpen((prevState) => !prevState);

  const [subDropdownOpen, setSubDropdownOpen] = useState(false);
  const toggleSubDropdown = () => setSubDropdownOpen(!subDropdownOpen);
  const [singleCategory, setSingleCategory] = useState("");
  const [subMeet, setSubMeet] = useState("");
  const [filterSubMeetingOpt, setFilterSubMeetingOpt] = useState([] || null);

  const { toggleFetch, triggerFetch } = useContext(TriggerBtnContext);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    startDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    endDate: new Date(),
    email: [],
    users: "",
    location: "",
    subCategory: "",
    meetingLink: "",
    theme: {
      value: "",
      label: "",
    },
  });
  const [theme, settheme] = useState("");
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [files4, setFiles4] = useState([]);
  const [ReminderStore, setReminderStore] = useState([]);
  const [userInputValue, setUserInputValue] = useState('');
  const [filterUserList, setFilterUserList] = useState([] || null);
  const { storageDetails, Storageloading } = useStorageUsage();
  // Storage filled alert logic
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [locationModal, setLocationModal] = useState(false);
  const locationToggle = () => setLocationModal(!locationModal);
  const [isFormTouched, setIsFormTouched] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [reminderformData, setReminderFormData] = useState([]);
  const toggle = () => {
    setModal(!modal);
    let currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + 15);
    setFormData({ ...formData, startTime: currentTime });
  };

  useEffect(() => {
    if (contextData) {
      // Check if the command contains "create new event on" followed by a date
      const createEventRegex = /create new event on (\d{1,2} \w+)(?: (\d{4}))?/i;
      const createEventMatch = contextData.match(createEventRegex);

      if (contextData === "create new event") {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-
        ${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-
        ${currentDate.getDate().toString().padStart(2, '0')}`;
        setModal(true);
        handleChildData(formattedDate);
        setContextData(null);
      } else if (createEventMatch) {
        const eventYear = createEventMatch[2] || new Date().getFullYear();
        const eventDate = new Date(createEventMatch[1]);
        const formattedDate = `${eventYear}-
        ${(eventDate.getMonth() + 1).toString().padStart(2, '0')}-
        ${eventDate.getDate().toString().padStart(2, '0')}`;
        setModal(true);
        handleChildData(formattedDate);
        setContextData(null);
      }
    }
  }, [contextData]);

  const handleChildData = (data) => {
    const [year, month, day] = data.split("-");
    const clickDate = new Date(`${month}-${day}-${year}`);
    const currentTime = new Date();
    const currentyear = currentTime.getFullYear();     // Get the year (e.g., 2024)
    const currentmonth = currentTime.getMonth() + 1;   // Get the month (1-12)
    const currentday = currentTime.getDate();          // Get the day of the month (1-31)
    const currentDate = new Date(`${currentmonth}-${currentday}-${currentyear}`);

    // Calculate the end time, which should be 15 minutes later
    const endDateTime = new Date(currentTime.getTime() + 60 * 60000);
    const startDateTime = new Date(currentTime.getTime() + 5 * 60000);

    // if (clickDate >= currentDate || clickDate.toDateString() === currentDate.toDateString()) {
    setFormData({
      ...formData,
      startDate: clickDate,
      startTime: startDateTime,
      endDate: clickDate,
      endTime: endDateTime,
    });
    setModal(!modal);
    // } else {
    //   toast.error("Previous Date's Not Available...");
    //   setModal(false);
    // }
  };
  // useEffect(() => {
  //   let intervalId;
  //   if (mockEvents.length > 0) {
  //     intervalId = setInterval(checkReminder(mockEvents), 3000);
  //   }
  //   return () => clearInterval(intervalId);
  // }, [mockEvents]);

  useEffect(() => {
    const date = formData.startDate;
    const enddate = new Date(date);
    setFormData(prevFormData => ({
      ...prevFormData,
      endDate: enddate
    }));
  }, [formData.startDate]);

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  const UserList = useUserList();
  useEffect(() => {
    getEvents();
  }, [triggerFetch]);
  async function getEvents() {
    try {
      const res = await MakeApiCall(`api/v1/calendar-events/list`);
      // console.log(res);
      if (res && res?.status) {
        let tempEvents = res?.data?.events.map((event) => {
          const formattedStartDateTime = getCurrentDateTimeInFormat(new Date(event?.start_date_time));
          const formattedEndDateTime = getCurrentDateTimeInFormat(new Date(event?.end_date_time));
          let newEvent = {
            id: "default-event-id-" + Math.floor(Math.random() * 9999999) + "-" + event?.id,
            eventId: event?.id,
            title: event?.event_title,
            start: formattedStartDateTime,
            end: formattedEndDateTime,
            startDate: new Date(event?.start_date_time),
            endDate: new Date(event?.end_date_time),
            startTimeObj: new Date(event?.start_date_time),
            endTimeObj: new Date(event?.end_date_time),
            email: event.email ? event?.email : [],
            users: event.users ? event?.users : [],
            user_id: event?.user_id,
            location: event?.location,
            link: event?.link,
            organizer_user_id: event?.organizer_user_id,
            organizer: event?.organizer_username,
            category: event?.category,
            subCategory: event?.subCategory,
            meetingLink: event?.meetingLink,
            description: event?.event_description,
            className: getCategoryValueByLabel(event?.category),
            type: getCategory(getCategoryValueByLabel(event?.category)),
            reminder: event?.reminder ? event?.reminder : [],
            parent_id: event?.parent_id,
            file_size: event?.file_size,
          };
          return newEvent;
        });
        updateEvent(tempEvents);
      } else {
        console.log("No events found");
      }
    } catch (error) {
      console.log("Error fetching event data:", error);
    }
  }
  const resetForm = () => {
    setFormData({
      title: "",
      description: "",
      startDate: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      endDate: new Date(),
      email: [],
      users: "",
      location: "",
      subCategory: "",
      meetingLink: "",
      theme: {
        value: "",
        label: "",
      },
    });
    setFiles4([]);
    setSubMeet("");
    setReminderStore([]);
    setReminderFormData([]);
    setIsFormTouched(false);
  };
  const onFormCancel = () => {
    if (formData.title.length !== 0
      || formData.description.length !== 0
      || formData.email.length !== 0
      || formData.users.length !== 0
      || formData.location.length !== 0
    ) {
      setModalSm(true);
    } else {
      setModal(false);
      setFiles4([]);
      resetForm();
    }
  };

  const onFormDiscard = (e) => {
    e.preventDefault();
    setModal(false);
    toggleSm()
    setFiles4([]);
    resetForm();
  }

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isFormTouched) {
        const message = "You have unsaved changes. Are you sure you want to leave?";
        e.returnValue = message;
        return message;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormTouched]);

  const handleFormSubmit = async (form) => {
    setLoading(true);
    if (!formData.startDate) {
      toast.error(<div>Enter start date</div>);
      setLoading(false);
      return false;
    }
    if (!formData.endDate) {
      toast.error(<div>Enter end date</div>);
      setLoading(false);
      return false;
    }
    if (!formData.startTime) {
      toast.error(<div>Enter start time</div>);
      setLoading(false);
      return false;
    }
    if (!formData.endTime) {
      toast.error(<div>Enter end time</div>);
      setLoading(false);
      return false;
    }
    let sd = formData.startDate;
    let ed = formData.endDate;

    let sd_t = formData.startTime;
    let ed_t = formData.endTime;
    let startDateTime = getCombinedDate(sd, sd_t);
    let endDateTime = getCombinedDate(ed, ed_t);

    const formattedStartDateTime = getCurrentDateTimeInFormat(startDateTime);
    const formattedEndDateTime = getCurrentDateTimeInFormat(endDateTime);

    // Ensure endTime is greater than startTime
    if (startDateTime.getTime() === endDateTime.getTime()) {
      endDateTime = new Date(endDateTime.getTime() + 60000); // Add 1 min 
    }

    const userEmail = formData.email;
    let addEventForm = new FormData();
    if (formData.title) addEventForm.append("event_title", formData.title);
    addEventForm.append("event_description", formData.description);
    addEventForm.append("start_date_time", getCurrentDateTimeInFormat(startDateTime));
    addEventForm.append("end_date_time", getCurrentDateTimeInFormat(endDateTime));
    addEventForm.append("visibility", "1");
    addEventForm.append("category", form?.theme?.label);
    addEventForm.append("subCategory", formData?.theme?.label === "Meeting" ? formData?.subCategory : "");
    addEventForm.append("meetingLink", formData?.theme?.label === "Meeting" ? formData?.meetingLink : "");
    addEventForm.append("reminder", JSON.stringify(reminderformData));
    addEventForm.append("status", "active");
    addEventForm.append("invite_by_email", userEmail);
    addEventForm.append("invite_by_username", formData.users);
    addEventForm.append("location", formData.location);
    if (files4?.[0]) addEventForm.append("event_attachment", files4?.[0]);
    // const entries = addEventForm.entries();
    const res = await MakeApiCall(`api/v1/calendar-events/add`, addEventForm);
    if (res?.status) {
      toggleFetch(loading);

      settheme({
        value: "",
        label: "",
      });
      toggle();
      resetForm();
      toast.success(res?.message);
    } else {
      toast.error(res?.message);
      setLoading(false);
      // if (res?.message?.includes("already exists")) {
      //   toast.error("Title already exists. Please choose a different title.");
      // } else {
      //   const ErrorMessage = res?.errors?.length > 0 ? res?.errors[0] : res?.message;
      //   toast.error(ErrorMessage);
      // }
    }
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };
  const handleUserSearch = async (e) => {
    const inputValue = e.map((option) => option.value);
    setFormData({ ...formData, users: inputValue });
  };
  useEffect(() => {
    const List = UserList.filter((option) =>
      option.label.toLowerCase().includes(userInputValue.toLowerCase())
    );
    setFilterUserList(List.slice(0, 5));
  }, [setFilterUserList, UserList, userInputValue]);

  useEffect(() => {
    const list = subMeetingOptions.filter((opt) =>
      opt.label.toLowerCase().includes(subMeet.toLowerCase())
    );
    setFilterSubMeetingOpt(list);
  }, [subMeet]);

  const editEvent = async (eventId, eventData) => {
    let sd = eventData.startDate;
    let ed = eventData.endDate;

    let sd_t = eventData.startTimeObj;
    let ed_t = eventData.endTimeObj;
    let startDateTime = getCombinedDate(sd, sd_t);
    let endDateTime = getCombinedDate(ed, ed_t);

    const formattedStartDateTime = getCurrentDateTimeInFormat(startDateTime);
    const formattedEndDateTime = getCurrentDateTimeInFormat(endDateTime);

    const formattedEventData = {
      ...eventData,
      start: formattedStartDateTime,
      end: formattedEndDateTime,
      startTimeObj: startDateTime,
      endTimeObj: endDateTime,
      className: eventData?.type?.value,
    };
    let uploadFilesResponse = await updateCalendarEventApi(eventId, formattedEventData);
    if (uploadFilesResponse && uploadFilesResponse.status) {
      toggleFetch(loading);
      toast.success(uploadFilesResponse.message);
      return true;
    } else if (uploadFilesResponse) {
      toast.error(uploadFilesResponse.message);
      return false;
    } else {
      toast.error("Error updating event.");
      return false;
    }
  };
  const deleteEvent = async (eventId) => {
    let responseData = await deleteCalendarEventApi(eventId);
    if (responseData.status) {
      toggleFetch(loading);
      const updatedEvents = mockEvents.filter((event) => event.eventId !== eventId);
      updateEvent(updatedEvents);
      toast.success(responseData.message);
    } else {
      toast.error(responseData.message);
    }
  };
  const handleChangeTags = (tags) => {
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const allTagsValid = tags.every((tag) => regexEmail.test(tag));
    setIsFormTouched(true);
    if (allTagsValid) {
      setFormData({ ...formData, email: tags });
    } else {
      toast.error("Enter valid email addresses");
    }
  };

  const handleDropdownSelect = (data) => {
    const { label, value } = data;
    const theme = { label: label, value: value };
    setFormData({ ...formData, theme: theme })
    setSingleCategory(theme.label);
    setDropdownOpen(false); // Close the dropdown after selection (if needed)
  };
  const handleSingleCategory = (e) => {
    const value = e.target.value;
    setSingleCategory(value);
    const theme = { label: value, value: null };
    setFormData({ ...formData, theme: theme })
    setIsFormTouched(true);
  }
  const handleSubMeetingSelect = (selectedItem) => {
    setFormData({ ...formData, subCategory: selectedItem.label });
    setSubMeet(selectedItem.label);
  }
  const handleSubMeetSerach = (e) => {
    const searchQuery = e.target.value;
    setSubMeet(searchQuery);
    setFormData({ ...formData, subCategory: searchQuery });
    setIsFormTouched(true);
  }

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (inputValue) {
        try {
          const response = await fetch(`https://api.mapbox.com/search/geocode/v6/forward?q=${inputValue}&proximity=ip&access_token=pk.eyJ1IjoicHJha2FzaG5pbiIsImEiOiJjbHk4aHp6MTgwajN1MmtzYjlnd3RkZzJlIn0.c0MhCeRKmQCZ6zIYCypLaw`);
          // console.log(response);
          if (response.ok) {
            const data = await response.json();
            setSuggestions(data.features);
            setLocationModal(true);
          }
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
        setLocationModal(false);
      }
    };

    const delayDebounceFn = setTimeout(() => {
      fetchSuggestions();
    }, 300); // Debounce input by 300ms

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  const handleData = (data) => {
    setFormData({ ...formData, location: data.properties.full_address });
    locationToggle();
    setSuggestions([]);
    setInputValue("");
  }
  const handleLocation = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setFormData({ ...formData, location: value });
    setIsFormTouched(true);
  }

  const handleChange = (index, field, value) => {
    const newValues = [...reminderformData];
    newValues[index][field] = value;
    setReminderFormData(newValues);
  };
  const handleAddField = () => {
    setReminderFormData([...reminderformData, { remindertype: 'sms', remindertime: 'h', minutehour: '1' }]);
  };

  const handleRemoveField = (index) => {
    const newValues = reminderformData.filter((_, i) => i !== index);
    setReminderFormData(newValues);
  };
  return (
    <React.Fragment>
      {
        !Storageloading && (
          <Modal isOpen={open} toggle={handleClose}>
            <ModalHeader toggle={handleClose}>
              <span className="fw-bold" style={{ color: theme.skin === "dark" ? "#fff" : "#000" }}>
                Storage Filled Alert!
              </span>
            </ModalHeader>
            <ModalBody>
              <p
                style={{
                  fontFamily: 'Roboto', fontWeight: '500',
                  color: theme.skin === "light" ? "#000" : "#f4bd0e"
                }}>
                Your storage has been filled with 90% or above. Please{' '}
                <a
                  href={storageDetails.upgradeLink}
                  style={{ color: theme.skin === "dark" ? "#fff" : "#000" }}
                  className="fw-bold"
                >
                  upgrade
                </a>{' '}
                the plan.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleClose}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )
      }
      <Head title="Silo Calender" />
      <ContentAlt>
        <Block>
          <PreviewAltCard >
            <CalenderApp
              onChildData={handleChildData}
              events={mockEvents}
              onDelete={deleteEvent}
              onEdit={editEvent}
              selectedCategory={theme}
              UserListRef={UserList}
            />
          </PreviewAltCard>
        </Block>
      </ContentAlt>

      <Modal isOpen={modal} toggle={() => onFormCancel()} className="modal-lg ">
        <ModalHeader toggle={() => onFormCancel()}>Add Event</ModalHeader>
        <ModalBody >
          <Modal size="sm" isOpen={modalSm} toggle={toggleSm}>
            <ModalHeader >
              <p className="fs-6">Discard unsaved changes?</p>
            </ModalHeader>
            <ModalFooter className="bg-light">
              <button
                type="button"
                onClick={toggleSm}
                className="bg-transparent fs-6 fw-bold border-0 text-secondary bttn-hover"
              >
                Cancel
              </button>
              <Button color="primary" onClick={(e) => onFormDiscard(e)} >
                Discard
              </Button>
            </ModalFooter>
          </Modal>
          <form
            className="form-validate is-alter"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(handleFormSubmit)();
            }}
          >
            <Row className="gx-4 gy-2">
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="pen" className="fs-4" /> */}
                  <img src={form_logo_pen} alt="pen" height="24" />
                </Col>
                <Col xs="auto" className="flex-grow-1">
                  <div className="form-group">
                    {/* <label className="form-label" htmlFor="event-title">
                      Event Title<span className="required-star">*</span>
                    </label> */}
                    <div className="form-control-wrap">
                      <input
                        placeholder="Enter Title"
                        type="text"
                        id="event-title"
                        // {...register("title", { required: true })}
                        value={formData.title}
                        onChange={(e) => {
                          setFormData({ ...formData, title: e.target.value });
                          setIsFormTouched(true);
                        }}
                        className="form-control"
                      />
                      {/* {errors.title && <p className="invalid">This field is required</p>} */}
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="calendar-alt" className="fs-4 " /> */}
                  <img src={form_logo_date} alt="pen" height="24" />
                </Col>
                <div className="d-flex flex-row flex-column flex-md-row  w-100">
                  <Col xs="auto" className="mb-1 me-0 me-md-1" >
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={formData.startDate}
                          onChange={(date) => setFormData({ ...formData, startDate: date })}
                          className="form-control date-picker"
                          minDate={new Date()}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="auto" className="mb-1 me-0 me-md-1" >
                    <div className="form-group">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={formData.startTime}
                          onChange={(date) => setFormData({ ...formData, startTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Col>
                  <Icon name="arrow-right" className="fs-5 pt-1 pe-1 d-none d-md-block" />
                  <Col xs="auto" className="mb-1 me-0 me-md-1" >
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={formData.endDate}
                          onChange={(date) => setFormData({ ...formData, endDate: date })}
                          className="form-control date-picker"
                          minDate={formData.startDate}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs="auto" >
                    <div className="form-group">
                      <div className="form-control-wrap has-timepicker">
                        <DatePicker
                          selected={formData.endTime}
                          onChange={(date) => {
                            if (formData.startTime.getTime() === date.getTime()) {
                              const endDateTime = new Date(date.getTime() + 60000); // Add 1 min 
                              setFormData({ ...formData, endTime: endDateTime });
                            } else {
                              setFormData({ ...formData, endTime: date });
                            }
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </div>

              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-2">
                  {/* <MdOutlineCategory className="fs-3" /> */}
                  <img src={form_logo_category} alt="pen" height="24" />
                </Col>
                <div className="d-flex flex-row flex-column flex-md-row  w-100">
                  <Col className="mb-1 me-0 me-md-1" xs="auto" md={`${formData?.theme?.label && formData?.theme?.label === "Meeting"
                    ? 3 : 12}`}>
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <UncontrolledDropdown style={{ width: "100%" }} isOpen={dropdownOpen} toggle={dropdowntoggle} direction="down">
                          <DropdownToggle caret className="p-0 border-0 w-100 "
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "inherit",
                              // Inline style to remove the hover effect
                              ':hover': {
                                backgroundColor: "transparent",
                                border: "none",
                                color: "inherit",
                              },
                            }}>
                            <input
                              className="form-control"
                              value={typeof formData.theme === "object" ? formData?.theme?.label : formData?.theme}
                              onChange={handleSingleCategory}
                              placeholder="Select Category"
                            />
                          </DropdownToggle>
                          <DropdownMenu
                            className="overflow-auto custom-scrollbar"
                            style={{
                              background: newTheme.skin === "dark" ? "#141c26" : "#fff",
                              height: "300px",
                            }}>
                            {eventOptions
                              .filter(item =>
                                item.label !== "Subscription Expire"
                                && item.label !== "Buy Subscription"
                                && item.label !== "Default"
                              )
                              .map((item, index) => (
                                <>
                                  <DropdownItem
                                    style={{
                                      background: newTheme.skin === "dark" ? "#141c26" : "#fff",
                                      color: "#8091a7"
                                    }}
                                    key={index}
                                    onClick={() => {
                                      handleDropdownSelect(item);
                                      dropdowntoggle();
                                      setIsFormTouched(true);
                                    }}
                                  >
                                    <GoDotFill style={{ color: `${item.color}` }} className="me-1 fs-5" />
                                    {item.label}
                                  </DropdownItem>

                                </>
                              ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </Col>
                  {
                    formData?.theme?.label && formData?.theme?.label === "Meeting" ?
                      (
                        <Col sm="auto" md="3" className="mb-1 me-0 me-md-1">
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <UncontrolledDropdown style={{ width: "100%" }} isOpen={subDropdownOpen} toggle={toggleSubDropdown} direction="down">
                                <DropdownToggle caret className="p-0 border-0 w-100"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "inherit",
                                    // Inline style to remove the hover effect
                                    ':hover': {
                                      backgroundColor: "transparent",
                                      border: "none",
                                      color: "inherit",
                                    },
                                  }}>
                                  <input
                                    className="form-control"
                                    value={subMeet}
                                    onChange={handleSubMeetSerach}
                                    placeholder="Meeting Held On"
                                  />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="overflow-auto custom-scrollbar"
                                  style={{ height: "300px" }}>
                                  {filterSubMeetingOpt.map((item, index) => (
                                    <DropdownItem
                                      style={{
                                        background: newTheme.skin === "dark" ? "#141c26" : "#fff",
                                        color: "#8091a7",
                                      }}
                                      key={index}
                                      onClick={() => {
                                        handleSubMeetingSelect(item);
                                        setIsFormTouched(true);
                                        toggleSubDropdown();
                                      }}
                                    >
                                      {item.img ? (
                                        <img
                                          src={item.img}
                                          alt={item.label}
                                          className="me-2"
                                          style={{ height: "20px", width: "20px" }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {item.label}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </Col>
                      ) : ""
                  }
                  {
                    formData?.theme?.label && formData?.theme?.label === "Meeting" ?
                      (
                        <Col xs="auto" className="w-100" >
                          <div className="form-group">
                            <div className="form-control-wrap">
                              <input
                                placeholder="Enter Link"
                                type="text"
                                id="link"
                                value={formData.meetingLink}
                                onChange={(e) => {
                                  setFormData({ ...formData, meetingLink: e.target.value });
                                  setIsFormTouched(true);
                                }}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </Col>
                      ) : ("")
                  }
                </div>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-1">
                  {/* <MdOutlineEditNote className="fs-1" /> */}
                  <img src={form_logo_description} alt="pen" height="26" />
                </Col>
                <Col xs="auto" className="w-100" >
                  <div className="form-group" style={{ marginLeft: "5px" }}  >
                    <div className="form-control-wrap">
                      <textarea
                        className="form-control"
                        id="event-description"
                        placeholder="Enter Description"
                        onChange={(e) => {
                          setFormData({ ...formData, description: e.target.value });
                          setIsFormTouched(true);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="map-pin" className="fs-3 " /> */}
                  <img src={form_logo_location} alt="pen" height="26" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group" >
                    <div className="form-control-wrap" id="Location">
                      <UncontrolledDropdown style={{ width: "100%" }}>
                        <DropdownToggle caret className="p-0 border-0 w-100 "
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            color: "inherit",
                            // Inline style to remove the hover effect
                            ':hover': {
                              backgroundColor: "transparent",
                              border: "none",
                              color: "inherit",
                            },
                          }}
                        >
                          <input
                            className="form-control "
                            value={formData?.location}
                            onChange={handleLocation}
                            placeholder="Add Location"
                          />
                        </DropdownToggle>
                        <DropdownMenu >
                          {inputValue && suggestions.length > 0 && (
                            <div className="bg-white shadow border d-flex flex-column rounded-2">
                              <div className="d-flex flex-column">
                                <div className="px-2">
                                  {suggestions.slice(0, 4).map((suggestion, index) => (
                                    <div
                                      key={index}
                                      onClick={() => handleData(suggestion)}
                                      style={{ paddingTop: "10px", paddingBottom: "10px", cursor: "pointer" }}
                                      className='border-bottom'
                                    >
                                      <p
                                        className="text-primary d-flex cursor-pointer m-0 flex-column ms-2"
                                        style={{ gap: "0.425rem", lineHeight: "16px" }}
                                      >
                                        <span className='fw-bold text-dark'>{suggestion.properties.name}</span>
                                        <small className='fw-light text-dark'>{suggestion.properties.full_address}</small>
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </Col>
              </div>

              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="users" className="fs-3" /> */}
                  <img src={form_logo_guests} alt="pen" height="26" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group">
                    <div className="form-control-wrap ">
                      <RSelect
                        placeholder="Add Guests"
                        options={filterUserList}
                        isMulti
                        onChange={handleUserSearch}
                        onInputChange={(value) => {
                          setUserInputValue(value);
                          setIsFormTouched(true);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center w-100">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="mail" className="fs-3" /> */}
                  <img src={form_logo_mail} alt="pen" height="26" />
                </Col>
                <Col xs="auto" className="w-100">
                  <div className="form-group">
                    <div className="form-control-wrap">
                      <TagsInput
                        className="form-control p-0 w-100"
                        value={Array.isArray(formData.email) ? formData.email : [formData.email]}
                        onChange={handleChangeTags}
                        inputProps={{
                          className: 'w-100 mb-0 react-tagsinput-input tagsinput-color  ',
                          placeholder: 'Invite Via Mail',
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </div>
              <div className="d-inline-flex align-items-center ">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="img" className="fs-3" /> */}
                  <img src={form_logo_img} alt="pen" height="24" />
                </Col>
                <Col xs="auto" className={`${files4.length > 0 && "w-100"}`}>
                  <Dropzone
                    onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setFiles4)}
                    accept={[".jpg", ".png", ".svg"]}
                    maxFiles={1}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="d-flex form-control p-0">
                        <div {...getRootProps()}
                          className="dropzone upload-zone dz-clickable p-0 border-0"
                          style={{ minHeight: "0", background: newTheme.skin === "dark" ? "#0d0c0c" : "#fcfafa" }}>
                          <input {...getInputProps()} />
                          {files4.length === 0 && (
                            <div className="dz-message m-0 mx-1 my-1">
                              <span className="dz-message-text">Drag And Drop File</span>
                            </div>
                          )}
                          {files4.map((file, index) => (
                            <div
                              key={index}
                              className="dz-preview dz-processing dz-image-preview dz-error dz-complete me-auto"
                            >
                              <div className="d-flex flex-row align-items-center">
                                <div className="dz-image">
                                  <img src={file?.preview} alt="preview" />
                                </div>
                                {
                                  file?.size &&
                                  <div className="ps-2">
                                    <p className="m-0">{shortenString(file?.name, 30)}</p>
                                    <p>Size : {formatFileSize(file?.size)}</p>
                                  </div>
                                }
                              </div>
                            </div>
                          ))}
                        </div>
                        {files4.length > 0 && (
                          <div className="align-center ms-auto pe-1">
                            {files4.map((file, index) => (
                              <div
                                key={file.name}
                                onClick={() => setFiles4([])}
                                aria-label="Remove"
                                className="align-center"
                              >
                                <Icon
                                  name="cross"
                                  className="fs-4 pointer form-control bg-transparent border-0 cross-btn"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </section>
                    )}
                  </Dropzone>
                </Col>
              </div>
              <div className="d-inline-flex w-100">
                <Col xs="auto" className="pe-2">
                  {/* <Icon name="alarm-alt" className="fs-3" /> */}
                  <img src={form_logo_remainder} alt="pen" height="28" className="mt-1" />
                </Col>
                <div className="d-flex flex-column w-100">
                  <Col xs="auto" className="">
                    {
                      reminderformData?.map((value, index) => (
                        <div key={index} className="d-flex flex-row flex-column flex-md-row w-100 pb-1">
                          <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <select
                                  className="form-control"
                                  value={value?.remindertype}
                                  onChange={(e) => {
                                    handleChange(index, 'remindertype', e.target.value);
                                    setIsFormTouched(true);
                                  }}
                                >
                                  <option value="sms">SMS</option>
                                  <option value="email">Email</option>
                                </select>
                              </div>
                            </div>
                          </Col>
                          <Col sm="1" className="mb-1 me-1 me-xs-0">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <label className="form-control border-0">Before</label>
                              </div>
                            </div>
                          </Col>
                          <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <input
                                  className="form-control"
                                  type="number"
                                  value={value?.minutehour}
                                  onChange={(e) => {
                                    handleChange(index, 'minutehour', Math.max(0, e.target.value));
                                    setIsFormTouched(true);
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs="auto" className="mb-1 me-1 me-xs-0 w-100">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <select
                                  className="form-control"
                                  value={value?.remindertime}
                                  onChange={(e) => {
                                    handleChange(index, 'remindertime', e.target.value);
                                    setIsFormTouched(true);
                                  }}
                                >
                                  <option value="h">Hours</option>
                                  <option value="m">Minutes</option>
                                </select>
                              </div>
                            </div>
                          </Col>
                          <Col xs="auto" className="">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <Icon
                                  name="cross"
                                  className="fs-4 pointer form-control bg-transparent border-0 cross-btn"
                                  onClick={() => handleRemoveField(index)} // Remove field on icon click
                                />
                              </div>
                            </div>
                          </Col>
                        </div>
                      ))
                    }
                  </Col>
                  <Col xs="12" sm="6" md="2">
                    <label
                      className="btn-color"
                      onClick={handleAddField}
                    >
                      Add Reminder
                    </label>
                  </Col>
                </div>
              </div>
              <Col size="12">
                <ul className="d-flex justify-content-center gx-5 ">
                  <li>
                    <Button type="submit" color="primary" disabled={loading}>
                      {loading ? (
                        <span className="align-center">
                          <Spinner size="sm" color="light" /> Loading...
                        </span>
                      ) : (
                        <span>Save Event</span>
                      )}
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal >
    </React.Fragment >
  );
};
export default Calender;