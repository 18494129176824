import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Calender from "../pages/app/calender/Calender";
import LayoutApp from "../layout/Index-app";
// import Cookies from "js-cookie"; // Add this if not already imported
// import { getCookie } from "../utils/Utils";
import { io } from "socket.io-client";
import { getCookie } from "../utils/Utils";
const Pages = () => {
  const location = useLocation();
  // const authToken = getCookie("authToken");

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // useEffect(() => {
  //   if (!authToken) {
  //     const now = new Date();
  //     const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
  //     Cookies.set("currentURL", window.location.href, {
  //       domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //       expires: expires,
  //     });

  //     window.location.href = process.env.REACT_APP_ACCOUNT_URL;
  //   } else {
  //     try {
  //       // KYC status check logic can be added here if necessary
  //     } catch (error) {
  //       console.error("Error checking KYC status:", error);
  //     }
  //   }
  // }, [authToken]);
  const username = getCookie("username");
  const moduleName = 6;
  const socket = io("https://live.silocloud.io");
  useEffect(() => {
    socket.on(`notification_${moduleName}_${username}`, (data) => {
      // console.log("Notification received:", data);
      // console.log(data);
      if (data) {
        if ("Notification" in window) {
          if (Notification.permission === "granted") {
            new Notification(`Notification: ${data.title}`, {
              body: `${data.body}`,
              icon: "https://api.silocloud.io/logo/apps/silo-calender.png",
            });
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                new Notification(`Notification: ${data.title}`, {
                  body: `${data.body}`,
                  icon: "https://api.silocloud.io/logo/apps/silo-calender.png",
                });
              }
            });
          }
        } else {
          console.log("Browser does not support notifications.");
        }
      }
    });

    return () => {
      socket.off(`notification_${moduleName}_${username}`);
    };
  }, []);
  return (
    <Routes>
      <Route
        // path={`${process.env.PUBLIC_URL}`}
        element={
          <LayoutApp
            app={{ icon: "calendar", theme: "bg-success-dim", text: "Silo Calendar" }}
          />
        }
      >
        <Route index element={<Calender />}></Route>
      </Route>
    </Routes>
  );
};

export default Pages;
