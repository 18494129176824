import Router from "./route/Index";
import ThemeProvider from "./layout/provider/Theme";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createContext, useState } from "react";
import { ContextProvider } from "./utils/contextData";

const queryClient = new QueryClient();
export const TriggerBtnContext = createContext(); // Export context

const App = () => {
  const [triggerFetch, setTriggerFetch] = useState(false);
  const toggleFetch = () => {
    setTriggerFetch(prev => !prev);
  };

  return (
    <TriggerBtnContext.Provider value={{ triggerFetch, toggleFetch }}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ContextProvider>
            <Router />
          </ContextProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </TriggerBtnContext.Provider>
  );
};

export default App;
