import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { toast } from "react-toastify";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { getCookie } from "../../../../utils/Utils";
import { fetchCloudSearchApi } from "../../../../utils/api-services";
import styles from "./App.module.scss"
import "./App.module.scss";

const AppDropdown = () => {
  const authToken = getCookie("authToken");
  const [isApps, setIsApps] = useState([]);
  const [loading, setLoading] = useState(true);
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const { skin, theme } = useTheme();

  const [login, setLogin] = useState(false);
  useEffect(() => {
    if (authToken) {
      setLogin(true);
    }
  }, [authToken]);

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen(!open);
  };

  const handleOldClick = (href, event) => {
    event.preventDefault();
    toast.info(
      <div>
        Very soon transforming to Silo 5.0,
        <br />
        in the meantime enjoy Silo 4.2
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      }
    );
    setTimeout(() => {
      window.location.href = href;
    }, 2000);
  };

  const handleClick = (app, event) => {
    if (app.oldsite) {
      handleOldClick(app.href, event);
    } else {
      toggle();
    }
  };

  const appmenudropdown = async (ev) => {
    ev.preventDefault();
    setOpen(!open);
    try {
      const result = await fetchCloudSearchApi("api/v1/apps/get-apps");
      setIsApps([
        result?.data?.core_apps || [],
        result?.data?.social_apps || [],
        result?.data?.productivity_apps || [],
        result?.data?.exchange_apps || [],
      ]);
    } catch (error) {
      console.error("Error fetching apps:", error);
      // toast.error("Failed to load apps");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#dropdown"
        onClick={appmenudropdown}
        className="dropdown-toggle nk-quick-nav-icon"
      >
        <div className="icon-status icon-status-na">
          <Icon name="menu-circled" />
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-lg border-0">
        <div className={`dropdown-body ${styles["apps-dropdown"]} overflow-auto`}>
          {loading ? (
            <div className="row m-0 mt-3">
              <div className="col-4">
                <ShimmerThumbnail height={70} rounded />
              </div>
              <div className="col-4">
                <ShimmerThumbnail height={70} rounded />
              </div>
              <div className="col-4">
                <ShimmerThumbnail height={70} rounded />
              </div>
              <div className="col-4">
                <ShimmerThumbnail height={70} rounded />
              </div>
              <div className="col-4">
                <ShimmerThumbnail height={70} rounded />
              </div>
              <div className="col-4">
                <ShimmerThumbnail height={70} rounded />
              </div>
              <div className="col-4">
                <ShimmerThumbnail height={70} rounded />
              </div>
              <div className="col-4">
                <ShimmerThumbnail height={70} rounded />
              </div>
              <div className="col-4">
                <ShimmerThumbnail height={70} rounded />
              </div>
            </div>
          ) : (
            isApps.map((main_app, main_index) => (
              <React.Fragment key={main_index}>
                <h6 className="px-3 pt-2 text-muted fw-normal">{main_app?.label}</h6>
                <ul className="list-apps">
                  {main_app?.data.map((app, index) => (
                    <li key={index} className={`p-0 ${styles["web-app"]} ${skin === "dark" ? styles["dark"] : ""}`}>
                      <a
                        href={app?.project_link
                          // app?.name === "Talk" && login
                          //   ? `https://silotalk.com/token-login/${authToken}`
                          //   : app?.project_link
                        }
                        onClick={(event) => handleClick(app, event)}
                      >
                        <span className="list-apps-media">
                          <img src={skin === "dark" ? app?.image_link : app?.dark_image_link} alt={app?.name} />
                        </span>
                        <div className="list-apps-title" dangerouslySetInnerHTML={{ __html: app?.name }} />
                      </a>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))
          )}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default AppDropdown;
