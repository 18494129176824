import React, { useEffect, useState, useRef } from 'react';
import { Block, Icon } from '../../../../components/Component';
import { Col, Row, Spinner, Tooltip } from 'reactstrap';
import { MakeApiCall, shortenString } from '../../../../utils/Utils';
import { formattedUSDate, getfullDate, getfullTime } from '../../../../components/partials/calender/CalenderData';
import { MakeApiCallBlobImage } from '../../../../utils/helper';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { placeHolderImg } from '../../../../images/applogos';
import { useTheme } from '../../../provider/Theme';

const EventNotification = ({ onSendChildData, eventId }) => {
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [files4, setFiles4] = useState([]);
    const [noImg, setNoImg] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const theme = useTheme();
    const imageCache = useRef({});
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipText, setTooltipText] = useState('Copy Link');
    const [modalSize, setModalSize] = useState(false);
    const [secondModal, setSecondModal] = useState(false);


    useEffect(() => {
        const fetchEvent = async () => {
            setLoading(true);
            try {
                const res = await MakeApiCall('api/v1/calendar-events/list');
                if (res?.status) {
                    setData(res?.data?.events || []);
                }
            } catch (error) {
                console.log(`Error From Event Notification Component: ${error}`);
            } finally {
                setLoading(false);
            }
        };
        fetchEvent();
    }, [eventId]);

    useEffect(() => {
        const fetchImage = async (event) => {
            setFileLoading(true);

            if (imageCache.current[event.id]) {
                setFiles4([{ preview: imageCache.current[event.id], name: event.title }]);
                setFileLoading(false);
            } else {
                try {
                    const formData = new FormData();
                    formData.append("calendar_id", event.id);
                    const result = await MakeApiCallBlobImage(`api/v1/calendar-events/get-event-attachment`, formData);

                    if (result) {
                        imageCache.current[event.id] = result;
                        setFiles4([{ preview: result, name: event.title }]);
                    } else {
                        setNoImg(true);
                    }
                } catch (error) {
                    console.log(`Error fetching image: ${error}`);
                    setNoImg(true);
                } finally {
                    setFileLoading(false);
                }
            }
        };

        if (eventId && data.length) {
            const event = data.find((e) => e.id === eventId);
            if (event) {
                setEvent(event);
                onSendChildData(event);
                fetchImage(event);
                if (event?.category || event?.subCategory || event?.meetingLink || event?.event_description || event?.location) {
                    setModalSize(true);
                } else setModalSize(false);
                if (event?.users?.length > 0 || JSON.parse(event?.reminder).length > 0) {
                    setSecondModal(true);
                } else setSecondModal(false);
            }
        }
    }, [data, eventId, onSendChildData]);

    if (loading) {
        return (
            <Block>
                <Row className="gy-3 py-1">
                    <Col sm="6" className="event-image">
                        <span className="align-center">
                            <Spinner size="sm" color="light" /> &nbsp;&nbsp;&nbsp;&nbsp;Loading...
                        </span>
                    </Col>
                </Row>
            </Block>
        );
    }

    if (!event) {
        return (
            <Block>
                <Row className="gy-3 py-1">
                    <Col sm="6">
                        <p>No event found</p>
                    </Col>
                </Row>
            </Block>
        );
    }

    // const handleCopy = (link) => {
    //     if (link) {
    //         navigator.clipboard.writeText(link)
    //             .then(() => {
    //                 setTooltipText('Copied!');
    //                 setTooltipOpen(true); // Show tooltip when copied

    //                 // Reset tooltip text back to "Copy Link" after 1.5 seconds
    //                 setTimeout(() => {
    //                     setTooltipText('Copy Link');
    //                 }, 1500);
    //             })
    //             .catch((err) => {
    //                 console.error('Failed to copy: ', err);
    //             });
    //     }
    // };
    return (
        <Block>
            <Row className="gy-3 py-1">
                <Col sm={`${secondModal && modalSize ? "4" : secondModal || modalSize ? "6" : "12"}`}>
                    <div className={`${secondModal || modalSize ? ""
                        : "d-flex justify-content-between"}`}>
                        <div>
                            <h6 className="overline-title m-0 m-0">Date</h6>
                            <div className="d-flex mb-1">
                                <p id="preview-event-start ">{getfullDate(event?.start_date_time)}</p>
                                {
                                    getfullDate(event?.start_date_time) === getfullDate(event?.end_date_time)
                                        ? ""
                                        : (
                                            <>
                                                &nbsp; - &nbsp;
                                                <p id="preview-event-start">{getfullDate(event?.end_date_time)}</p>
                                            </>
                                        )
                                }
                            </div>
                            <h6 className="overline-title m-0">Time</h6>
                            <div className="d-flex">
                                <p id="preview-event-end">{getfullTime(event?.start_date_time)}</p>
                                &nbsp; - &nbsp;
                                <p id="preview-event-end">{getfullTime(event?.end_date_time)}</p>
                            </div>
                        </div>
                        <div>
                            {
                                fileLoading ?
                                    <ShimmerThumbnail height={150} width={260} rounded />
                                    :
                                    // noImg === null ?
                                    //     // <img src={placeHolderImg} style={{ height: "150px", width: "260px" }} />
                                    //     ""
                                    //     : 
                                    (
                                        files4.map((file) => (
                                            <span className="align-center" style={{ height: "150px", width: "260px" }}>
                                                <img key={file.name} src={file.preview} alt="preview" className='img-fluid'
                                                    style={{ height: "100%", width: "auto" }} />
                                            </span>
                                        ))
                                    )
                            }
                        </div>
                    </div>
                </Col>
                {
                    modalSize &&
                    <Col sm={`${secondModal ? "4" : "6"}`}>
                        <div className="d-flex flex-row justify-content-between mb-1">
                            <div>
                                {
                                    event?.category &&
                                    <>
                                        <h6 className="overline-title m-0">Category</h6>
                                        <p id="preview-event-category">{event?.category}</p>
                                    </>
                                }
                            </div>
                            <div>
                                {
                                    event?.subCategory &&
                                    <>
                                        <h6 className="overline-title m-0">Meeting Held On</h6>
                                        <p id="preview-event-category">{event?.subCategory}</p>
                                    </>
                                }
                            </div>
                        </div>
                        <h6 className="overline-title m-0">{event?.meetingLink && "Meeting Link"}</h6>
                        <div className="d-flex flex-row justify-content-between">
                            <p id="preview-event-category " className="pointer"
                                onClick={() => window.location.href = event?.meetingLink}>
                                {event?.meetingLink && shortenString(event?.meetingLink, 40)}
                            </p>
                            {/* {
                                event?.meetingLink && (
                                    <>
                                        <div id="copyIcon">
                                            <Icon name="copy" className="fs-5 px-1 fw-bold" onClick={() => handleCopy(event?.meetingLink)} />
                                        </div>
                                        <Tooltip
                                            placement="top"
                                            isOpen={tooltipOpen}
                                            target="copyIcon"
                                            toggle={() => setTooltipOpen(!tooltipOpen)}
                                        >
                                            {tooltipText}
                                        </Tooltip>
                                    </>
                                )
                            } */}
                        </div>

                        {
                            event?.event_description &&
                            <>
                                <h6 className="overline-title m-0">Description</h6>
                                <p id="preview-event-description" style={{ maxHeight: "100px", overflow: "auto" }}
                                    className="text-break custom-scrollbar">
                                    {event?.event_description}
                                </p>
                            </>
                        }
                        {
                            event?.location &&
                            <p id="preview-event-category"
                                style={{ cursor: "pointer" }}
                            // onClick={() => handleMapLocation(event?.location)}
                            >
                                <em class="icon ni ni-map-pin-fill fs-5 pe-1" />
                                {event?.location}
                            </p>
                        }
                    </Col>
                }

                {
                    secondModal &&
                    <Col sm={modalSize ? "4" : "6"} className="">
                        {event?.users?.length > 0 && (
                            <>
                                <h6 className="overline-title m-0">Organiser</h6>
                                <p id="preview-event-description" className="text-break">{event?.organizer_username}</p>
                                <h6 className="overline-title m-0">Invited Guest</h6>
                                <div className="overflow-auto custom-scrollbar" style={{ maxHeight: "140px" }}>
                                    {event?.users?.map((item, index) => (
                                        <p key={index} id="preview-event-description" className="text-break mb-0 border-bottom">
                                            {item}
                                        </p>
                                    ))}
                                </div>
                            </>
                        )}

                        {
                            JSON.parse(event?.reminder).length > 0 && (
                                <div className="pt-2 align-items-center">
                                    <Col xs="auto" className="p-0">
                                        <Icon name="alarm-alt" className="fs-4 pt-1" />
                                    </Col>
                                    <Col >
                                        <div className="overflow-auto custom-scrollbar" style={{ maxHeight: "100px" }}>
                                            {
                                                JSON.parse(event?.reminder).map((item, index) => (
                                                    <p key={index} id="preview-event-description"
                                                        className="text-break pe-1 mb-0 border-bottom ">
                                                        {`You'll get a reminder ${item.minutehour} 
                                                        ${item.remindertime === 'm' ? 'minutes' : 'hours'} 
                                                        before via ${item.remindertype.toUpperCase()}.`}
                                                    </p>
                                                ))
                                            }
                                        </div>
                                    </Col>
                                </div>
                            )
                        }
                    </Col>
                }
            </Row>
        </Block >
    );
};

export default EventNotification;
